//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Tabs'
    },
    data() {
        return {
            tab: null,
            tabs: null,
            items: [
                {tab: 'One', content: 'Tab 1 Content'},
                {tab: 'Two', content: 'Tab 2 Content'},
                {tab: 'Three', content: 'Tab 3 Content'},
                {tab: 'Four', content: 'Tab 4 Content'},
                {tab: 'Five', content: 'Tab 5 Content'},
                {tab: 'Six', content: 'Tab 6 Content'},
                {tab: 'Seven', content: 'Tab 7 Content'},
                {tab: 'Eight', content: 'Tab 8 Content'},
                {tab: 'Nine', content: 'Tab 9 Content'},
                {tab: 'Ten', content: 'Tab 10 Content'}
            ],
            images: [
                require('@/assets/images/gallery/sq-4.jpg'),
                require('@/assets/images/gallery/sq-5.jpg'),
                require('@/assets/images/gallery/sq-6.jpg'),
                require('@/assets/images/gallery/sq-7.jpg'),
                require('@/assets/images/gallery/sq-8.jpg'),
                require('@/assets/images/gallery/sq-9.jpg')
            ]
        }
    }
}
