//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'List'
    },
    data: () => ({
        item: 1,
        items: [
            {text: 'Real-Time', icon: 'mdi-clock'},
            {text: 'Audience', icon: 'mdi-account'},
            {text: 'Conversions', icon: 'mdi-flag'}
        ],
        itemsTwo: [
            {
                icon: true,
                title: 'Jason Oner',
                avatar: require('@/assets/images/faces/1.jpg')
            },
            {
                title: 'Travis Howard',
                avatar: require('@/assets/images/faces/2.jpg')
            },
            {
                title: 'Ali Connors',
                avatar: require('@/assets/images/faces/3.jpg')
            },
            {
                title: 'Cindy Baker',
                avatar: require('@/assets/images/faces/4.jpg')
            }
        ],
        itemsThree: [
            {
                icon: 'mdi-folder',
                iconClass: 'grey lighten-1 white--text',
                title: 'Photos',
                subtitle: 'Jan 9, 2014'
            },
            {
                icon: 'mdi-folder',
                iconClass: 'grey lighten-1 white--text',
                title: 'Recipes',
                subtitle: 'Jan 17, 2014'
            },
            {
                icon: 'mdi-folder',
                iconClass: 'grey lighten-1 white--text',
                title: 'Work',
                subtitle: 'Jan 28, 2014'
            }
        ],
        itemsFour: [
            {
                icon: 'assignment',
                iconClass: 'blue white--text',
                title: 'Vacation itinerary',
                subtitle: 'Jan 20, 2014'
            },
            {
                icon: 'call_to_action',
                iconClass: 'amber white--text',
                title: 'Kitchen remodel',
                subtitle: 'Jan 10, 2014'
            }
        ],
        itemsFive: [
            {
                action: 'mdi-folder',
                title: 'Attractions',
                items: [{title: 'List Item'}]
            },
            {
                action: 'mdi-folder',
                title: 'Dining',
                active: true,
                items: [
                    {title: 'Breakfast & brunch'},
                    {title: 'New American'},
                    {title: 'Sushi'}
                ]
            },
            {
                action: 'mdi-folder',
                title: 'Education',
                items: [{title: 'List Item'}]
            },
            {
                action: 'mdi-folder',
                title: 'Family',
                items: [{title: 'List Item'}]
            },
            {
                action: 'mdi-folder',
                title: 'Health',
                items: [{title: 'List Item'}]
            },
            {
                action: 'mdi-folder',
                title: 'Office',
                items: [{title: 'List Item'}]
            },
            {
                action: 'mdi-folder',
                title: 'Promotions',
                items: [{title: 'List Item'}]
            }
        ]
    })
}
