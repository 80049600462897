//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    basicLineChart,
    lineChartWIthDataLabel,
    gradientLineChart,
    dashedLineChart,
    brushLineChart
} from '@/data/apexChart'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Apex- Line Charts'
    },
    data() {
        return {
            basicLineChart,
            lineChartWIthDataLabel,

            gradientLineChart,
            dashedLineChart,
            brushLineChart
        }
    }
}
