//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
import {priceChart, marketChart} from '@/data/cryptoCurrency'
import ChartCard from '@/components/card/ChartCard'

import {
    customChartOne,
    customChartTwo,
    customChartThree,
    customChartFour,
    customChartFive,
    customChartSix,
    customChartSeven,
    customChartEight,
    customChartNine,
    customChartTen,
    customChartEleven,
    customChartTwelve,
    customChartThirteen,
    customChartFourteen,
    customChartFifteen,
    customChartSixteen,
    customChartSeventeen,
    customChartEighteen
} from '@/data/apexChart'
export default {
    name: 'crypto-currency',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'crypto-currency'
    },
    components: {
        CryptoCurrencyCard,
        ChartCard
    },
    data() {
        return {
            customChartOne,
            customChartTwo,
            customChartThree,
            customChartFour,
            customChartFive,
            customChartSix,
            customChartSeven,
            customChartEight,
            customChartNine,
            customChartTen,
            customChartEleven,
            customChartTwelve,
            customChartThirteen,
            customChartFourteen,
            customChartFifteen,
            customChartSixteen,
            customChartSeventeen,
            customChartEighteen,
            priceChart,
            marketChart,
            tab: null,
            items: [
                {
                    tab: 'USDT',
                    content: [
                        {
                            coin: 'BTC',
                            lastPrice: 4000.32,
                            number: 6.4,
                            percent: '(-3.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'DOGE',
                            lastPrice: 1782.99,
                            number: 2.4,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'XMR',
                            lastPrice: 2398.54,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ERC20',
                            lastPrice: 3456.99,
                            number: 5.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'CFT',
                            lastPrice: 7826.0,
                            number: 6.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'RIF',
                            lastPrice: 4000.32,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'NEO',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'MXM',
                            lastPrice: 1782.99,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'DOGE',
                            lastPrice: 1782.99,
                            number: 2.4,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'XMR',
                            lastPrice: 2398.54,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'CFT',
                            lastPrice: 7826.0,
                            number: 6.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'RIF',
                            lastPrice: 4000.32,
                            number: 3.5,
                            percent: '(-5.2%)'
                        }
                    ]
                },
                {
                    tab: 'Market Trading',
                    content: [
                        {
                            coin: 'BTC',
                            lastPrice: 4000.32,
                            number: 6.4,
                            percent: '(-3.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'DOGE',
                            lastPrice: 1782.99,
                            number: 2.4,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'XMR',
                            lastPrice: 2398.54,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ERC20',
                            lastPrice: 3456.99,
                            number: 5.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'CFT',
                            lastPrice: 7826.0,
                            number: 6.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'RIF',
                            lastPrice: 4000.32,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'NEO',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'MXM',
                            lastPrice: 1782.99,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'DOGE',
                            lastPrice: 1782.99,
                            number: 2.4,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'XMR',
                            lastPrice: 2398.54,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'CFT',
                            lastPrice: 7826.0,
                            number: 6.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'RIF',
                            lastPrice: 4000.32,
                            number: 3.5,
                            percent: '(-5.2%)'
                        }
                    ]
                }
            ],
            order: null,
            orders: [
                {
                    tab: 'Active Orders',
                    content: [
                        {
                            coin: 'BTC',
                            lastPrice: 4000.32,
                            number: 6.4,
                            percent: '(-3.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'DOGE',
                            lastPrice: 1782.99,
                            number: 2.4,
                            percent: '(-5.2%)'
                        }
                    ]
                },
                {
                    tab: 'Closed Orders',
                    content: [
                        {
                            coin: 'BTC',
                            lastPrice: 4000.32,
                            number: 6.4,
                            percent: '(-3.2%)'
                        },
                        {
                            coin: 'LTC',
                            lastPrice: 7826.0,
                            number: 2.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        },
                        {
                            coin: 'ETH',
                            lastPrice: 3456.99,
                            number: 3.5,
                            percent: '(-5.2%)'
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        getColor(lastPrice) {
            if (lastPrice > 4000) return 'danger--text'
            else if (lastPrice > 1000) return 'success--text'
            else return 'priamry--text'
        },
        changeColor(number) {
            if (number > 4) return 'danger white--text'
            else if (number <= 3) return 'success white--text'
            else return 'primary white--text'
        }
    }
}
