//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Faq Two'
    },
    data() {
        return {
            tab: null,
            items: [
                {tab: '説明一覧', content: 'Tab 1 Content'},
                {tab: 'Pricing', content: 'Tab 2 Content'}
            ],
            itemsTwo: [
                {
                    tab: '始め方について',
                    content:
                        'まずはデータの入力から始めましょう。左に表示されている名簿をクリックして、門徒様の情報をご入力ください。'
                },
                {
                    tab: 'データ移行について',
                    content:
                        '当システムではcsv、excelデータの読み込みが可能なため、別のシステムのデータで扱っていたデータを指定フォーマットに合わせて頂くことで移行することができます。'
                },
                {
                    tab: 'データ保存について',
                    content:
                        '当システムのデータベースに厳重に保存しております。またcsv、excelデータとして出力することが可能なため、ユーザー様の方で保管することも可能です'
                }
                ],

             itemsThree: [
                {
                    tab: 'カレンダー機能について',
                    content:
                        'スケジュールをカレンダーに追加することができます。また担当者の方が報告書を書くことも可能です。'
                },
                {
                    tab: '帳簿管理について',
                    content:
                        '帳簿に収支情報を入力することができます。門徒様との紐づけが可能です。'
                },
                {
                    tab: 'その他の質問について',
                    content:
                        '以下のマニュアルをお読みください。またそれでも不明な場合は、個別にメールにて対応させて頂きます。'
                }
                ]
            
        }
    }
}
