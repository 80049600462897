//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from "@/components/card/ChartCard";
import {
  basicColumnChart,
  columnDataLabels,
  stackedColumn,
  negativeColumn,
  distributedColumnChart,
} from "@/data/apexChart";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Apex- Column Charts",
  },
  components: {
    ChartCard,
  },
  data() {
    return {
      basicColumnChart,
      columnDataLabels,
      stackedColumn,
      negativeColumn,
      distributedColumnChart,
    };
  },
};
