//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Error',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Error'
    }
}
