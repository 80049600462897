//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns/fp';
import avatarGroupCard from '@/components/card/AvatarGroupCard'
import moment from 'moment';
import axios from 'axios';
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile 1'
    },
    components: {
        avatarGroupCard,   
    },

    data() {
        return {
            dialog_type: '',
            tab: null,
            items: [
                {tab: '基本情報', content: 'Tab 1 Content'},
                {tab: '近況情報', content: 'Tab 2 Content'},
                {tab: '過去帳', content: 'Tab 3 Content'},
                {tab: '個別帳簿', content: 'Tab 4 Content'},
                {tab: '月参り・祥月命日', content: 'Tab 5 Content'},
                {tab: '個別スケジュール', content: 'Tab 6 Content'},
                {tab: 'メモ', content: 'Tab 7 Content'},
            ], 
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            family_name:"",
            family_relationship:"",
            homyo:"",
            name:"",
            death_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            homyo_relationship:"",
            grave_info:"",
            funeral_info:"",
            deceased_person_note:"",
            name_content: "", 
            payment_name:"",
            money_content:"",
            money_amount:"",
            money_note:"",
            money_confirmation:false,
            client: "",
            worker: "",
            content:"",
            zip:"",
            address:"",
            select: null,
            gender_selections:["男性","女性","その他"],
            member_type_selections:["檀家", "門徒",  "信徒", "一般", "離檀"],
            service_selections: [
                '月参り',
                '祥月命日',
                '法事',
                'お葬式',
                'お通夜'
            ],
            payment_selections: [
                '護持会費',
                '護持会寄付',
                '納骨堂年間冥加金',
                '納骨堂寄付',
                '境内墓地年間冥加金',
                '境内墓地寄付',
                '合同墓納骨日',
                'お布施',
                'その他',
            ],
            note:"",
            checkbox: false,
            errorMessages:"",
            menu:"",
            inputDate:"",
            service_plan_date:1,
            service_plan_type:"月参り",
            service_plan_address: "",
            service_plan_memo: "",
            memo_name: "",
            memo_content:"",
            //dateRule_yyyymmdd: /^[0-9]{4}[/](0[1-9]|1[0-2])[/](0[1-9]|[12][0-9]|3[01])$/,
            dateRule_yyyymmdd:  /^\d{4}\/(0?[1-9]|1[0-2])\/\d{1,2}$/,
            dateRule_mmdd: /^([1-9]|1[0-2])[/](0[1-9]|[12][0-9]|3[01])$/,
            dateRule_dd: /^([1-9]|[12][0-9]|3[01])$/, //1から9と31日までを許可

            // 入力規則1
            required: value => !!value || "必ず入力してください", // 入力必須の制約
            // 入力規則2
            yyyymmdd: value => {
                const pattern = this.dateRule_yyyymmdd
                return pattern.test(value) || 'yyyy/mm/dd形式で入力してください'},
            // 入力規則3
            mmdd: value => {
                const pattern = this.dateRule_mmdd
                return pattern.test(value) || '月と日にちを入力してください'},
            // 入力規則4
            dd: value => {
                const pattern = this.dateRule_dd
                return pattern.test(value) || '日にちを入力してください'},
            charcode: "UTF8", //デフォルト文字コード,
            dialog: false,
            menu1:"",
            menu2:"",
            dragEvent: null,
            createEvent: null,
            createStart: null,
            event_id: 0,
            event_start_date: "2000-1-01",
            event_end_date: "",
            event_start_time: null,
            event_end_time: null,
            event_name : null,
            event_opponent : null,
            event_dealer : null,
            event_address : null,
            event_memo : null,
            switch_start_fulldate: false,
            switch_end_fulldate: false,
            EditModeActive: false,
            original_profile_data :[], // 初期値を入れとく変数
            original_homyos:[],
            original_account_book:[],
            original_reports:[],
            original_service_plans:[],
            original_events:[],
            original_family:[],
            original_memo:[],
            value: moment().format('yyyy-MM-DD'),
            type: "month",
            types: [{id:"month", text: '月'},{id:"week", text: '週'},{id: "day", text: '日'}, {id:"4day", text: '4日'}],
            mode: "stack",
            modes: [{id:"stack", text: '可'},{id:"column", text: '不可'}],
            weekday: [0, 1, 2, 3, 4, 5, 6],
            weekdays: [
                { text: "日曜日 - 土曜日", value: [0, 1, 2, 3, 4, 5, 6] },
                { text: "月曜日 - 日曜日", value: [1, 2, 3, 4, 5, 6, 0] },
                { text: "月曜日 - 金曜日", value: [1, 2, 3, 4, 5] },
                { text: "金曜日, 土曜日, 日曜日", value: [5, 6, 0] }
            ],
            member_id:this.getParam('id'),
            
                profile_data:{
                member_id:this.getParam('id'),
                house_name:'月波月見',     
                house_name_kana:'ツキナミツキミ' ,
                member_type: '門徒',
                gender: '女性',
                age: '81',
                birth_date: '1980/01/01',
                address:'大阪府池田市1-10-1',
                house_phone_number: '080-2424-1111',
                cell_phone_number: '080-2424-1111',
                mail_address: 'test@gmail.com',
                letter_send: true,
                joined_date: '2020/02/01',
                availability:true,
                caretaker:"寺田正",
                has_grave: true,
                has_ossuary: true
                },

                family: [
                // {name: '鈴木一郎', relationship:'夫'},
                // {name: '鈴木三郎',relationship:'子'},
                // {name: '鈴木花子',relationship:'子'},
                // {name: '鈴木聡子',relationship:'いとこ'},
                // {name: '田中一郎',relationship:'弟'},
                // {name: '太田大子', relationship:'叔母'}
            ],

                homyos:[
                // { id:1,
                //   homyo: '釈太三郎',
                //   member_id:this.getParam('id'),
                //   date_of_death: '2025/08/14',
                //   name: '鈴木太三郎',
                //   relationship: '父',
                //   grave:'お寺所有の墓地にあり',
                //   funeral:'寺町会館で行った',
                //   memo:"昔お寺の近くに住んでいた"
                // },  
                // { id:2,
                //   homyo: '釈蓮華',
                //   member_id:this.getParam('id'),
                //   date_of_death: '2025/08/01',
                //   name: '鈴木園子',
                //   relationship: '母',
                //   grave:'お寺所有の墓地にあり',
                //   funeral:'北町会館で行った', 
                //   memo:"特になし"}
                ],

                account_book:[
                // { id:1,
                //   date: '2022/01/01',
                //   member_id:this.getParam('id'),
                //   title: '護持会費',
                //   content: '毎月の護持会費',
                //   amount: 3000,
                //   memo:"特になし",
                //   confirmation: true,
                // },
                // { id:2,
                //   date: '2021/01/01',
                //   member_id:this.getParam('id'),
                //   title: '納骨堂年間冥加金',
                //   content: 'その他',
                //   amount: 10000,
                //   memo:"納骨堂のお金",
                //   confirmation: false,
                // },
            ],

                reports: [
                    
                // {   id:"",
                //     member_id:this.getParam('id'),
                //     date: '2022/01/01',
                //     title: '祥月参り',
                //     dealer: "武田年三",
                //     content: '今日はいつも通り和讃を一緒に唱えて、その後、少しお話ししました。最近、体の調子が良いようで、散歩が趣味のようです。',
                //     address: "大阪府吹田市",
                //     memo:"特になし"
                // },
                // {   id:"",
                //     member_id:this.getParam('id'),
                //     date: '2022/01/05',
                //     title: '仏具相談',
                //     dealer: "武田年三",
                //     content: '今日は和讃と正信偈を唱えました。今日は親鸞聖人についてお話しをして、お勧めの本を教えました。',
                //     address: "大阪府吹田市",
                //     memo:"特になし"
                // },
                // {   id:"",
                //     member_id:this.getParam('id'),
                //     date: '2021/02/07',
                //     title: '法事',
                //     dealer: "武田年三",
                //     content: '今日も和讃を唱えました。お墓についての相談があるようなので、またお寺からお話しをしてもらいたいようです。',
                //     address: "大阪府吹田市",
                //     memo:"特になし"
                //     }
                ],

                service_plans:[
                // {   id: 1,
                //     date:"17",
                //     service_type: "月参り",
                //     address: "大阪府吹田市",
                //     memo:"特になし",
                //     reflect_on_schedule: true
                // },
                // {   id: 2,
                //     date:"1/1",
                //     service_type: "祥月命日",
                //     address: "大阪府吹田市",
                //     memo:"特になし",
                //     reflect_on_schedule: true
                // },

                ],

            events:[

    //     {
    //       id: 1,
    //       name: '会議　宮崎太郎　(住職)',  
    //       member_id:this.getParam('id'),               
    //       event_name:'会議',
    //       event_opponent: '宮崎太郎',
    //       event_dealer: '住職',
    //       event_address:'大阪府池田市綾羽1-4-12',
    //       event_memo:'過去帳を持っていくこと',
    //       start: new Date('2022-08-03 10:00:00'),
    //       end: new Date('2022-08-03 11:00:00'),
    //       color: 'green',
    //       timed: true,
    //       start_day_timed: true,
    //       end_day_timed: true
    //     },
            ],

        memo_info:[
                // { id:"",
                //   member_id:this.getParam('id'),
                //   date: '2022/01/01',
                //   title: 'お葬式',
                //   content: '電話にて相談事について話していた',
                // },
                // { id:"",
                //   member_id:this.getParam('id'),
                //   date: '2021/01/01',
                //   title: '納骨堂年間冥加金',
                //   content: '電話にて相談を受けた',
                // },
            ],

        }
    },
    mounted: function() {

        // URLパラメータ文字列を取得する
        let member_id = this.member_id;
        this.getEvents(member_id)
        this.getMemberInfo(member_id)
        this.getUserInfo(member_id)
        

        //this.planed_service_to_schedule()
        this.sortedByDates() //年度順に並び替えしたものを初期値に設定する

        this.original_profile_data = JSON.parse(JSON.stringify(this.profile_data));
        this.original_homyos = JSON.parse(JSON.stringify(this.homyos));
        this.original_account_book = JSON.parse(JSON.stringify(this.account_book));
        this.original_reports = JSON.parse(JSON.stringify(this.reports));
        this.original_service_plans = JSON.parse(JSON.stringify(this.service_plans));
        this.original_events = JSON.parse(JSON.stringify(this.events));
        this.original_family = JSON.parse(JSON.stringify(this.family));
        this.original_memo = JSON.parse(JSON.stringify(this.memo_info));

        //住所初期値入力
        this.service_plan_address = JSON.parse(JSON.stringify(this.profile_data.address));
        this.address = JSON.parse(JSON.stringify(this.profile_data.address));
        

    },
computed: {

    title() {
      return moment(this.value).format('yyyy年M月');
    },
  },

methods: {
    customFormatter(date) {
      return moment(date).format('YYYY/MM/DD');
    },

    getMemberInfo(id) {

        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

        axios.get('https://www.tsukinami-system.com/fastapi/member?id=' + id, config )
        .then(response => {
        console.log(response.data)

        // 成功時の処理etc
        let data = response.data

        this.profile_data= {
                  
                  member_id: data.id,
                  house_name: data.name,
                  member_type: data.type,
                  zip_code: data.zipcode,
                  address:data.address,
                  availability: data.contactable,
                  house_phone_number:data.phone_number,
                  joined_date: data.start_date,
                  letter_send: data.letter
              }
            
        this.event_opponent =  this.profile_data.house_name
    
        //ここでデータ保持 全てのデータを取得し、初期値をここで保存する
        this.original_profile_data = JSON.parse(JSON.stringify(this.profile_data));


        // this.original_homyo = JSON.parse(JSON.stringify(this.homyo));
        // this.original_account_book = JSON.parse(JSON.stringify(this.account_book));
        // this.original_reports = JSON.parse(JSON.stringify(this.reports));
        // this.original_service_plans = JSON.parse(JSON.stringify(this.service_plans));
        // this.original_events = JSON.parse(JSON.stringify(this.events));
        // this.original_family = JSON.parse(JSON.stringify(this.family));
        // this.original_memo = JSON.parse(JSON.stringify(this.memo_info));


        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
    },
    
    sortedByDates() {
        return this.reports.sort((a, b) => {
            //console.log(new Date(a.date))
          return new Date(a.date) - new Date(b.date);
        });
      },
      MathAnnulalEvent (DeathYear, AnniversaryYear) {

        try {
            var dt = new Date(DeathYear);
            dt.setFullYear(dt.getFullYear() + AnniversaryYear);
            const formatTime = format('yyyy/MM/dd');
            var ConvertedYear = formatTime(dt) 
                return ConvertedYear
            }catch( e ) {
                return 
            }
      },
     MathdateEvent (DeathDate, countdate) {

            try {
                var dt = new Date(DeathDate);
                dt.setDate(dt.getDate() + countdate);
                const formatTime = format('yyyy/MM/dd');
                var ConvertedYear = formatTime(dt) 
                return ConvertedYear
            }catch( e ) {
                return 
            }
      },


    // 編集保存関数
    active: function () {
        let Equal1; let Equal2; let Equal3; let Equal4; let Equal5; let Equal6; let Equal7;

        if(this.isEqualObject()){Equal1 = true}else{Equal1 = false} // ここだけ通常の配列
        if(this.isEqualArray(this.original_homyos, this.homyos)){Equal2 = true}else{Equal2 = false}
        if(this.isEqualArray(this.original_account_book, this.account_book)){Equal3 = true}else{Equal3 = false}
        if(this.isEqualArray(this.original_reports, this.reports)){Equal4 = true}else{Equal4 = false}
        if(this.isEqualArray(this.original_events, this.events)){Equal5 = true}else{Equal5 = false}
        if(this.isEqualArray(this.original_family, this.family)){Equal6 = true}else{Equal6 = false}
        if(this.isEqualArray(this.original_memo, this.memo_info)){Equal7 = true}else{Equal7 = false}

        const checkbox = [Equal1, Equal2, Equal3, Equal4, Equal5, Equal6, Equal7]
        const Equality = checkbox.every(value => value == true)
        
        // 編集完了し、編集内容を保存するか確認 
        if(this.EditModeActive == true && Equality == false){
            
            const answer1 = window.confirm("編集内容を保存してもよろしいでしょうか？")
            if(answer1){

                let user_info = localStorage.getItem('userInfo')
                user_info = JSON.parse(user_info)
                let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
                

                // let dic_data ={"reports":reports, "memo":memos, "homyo":homyo}
                //門徒の基本情報編集APIも投げる
                //DB編集APIを投げる
                axios.post('https://www.tsukinami-system.com/fastapi/reports', this.reports, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });


                
                axios.post('https://www.tsukinami-system.com/fastapi/homyo', this.homyos, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });

                axios.post('https://www.tsukinami-system.com/fastapi/account_books',this.account_book, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });

                
                //個人スケジュール
                axios.post('https://www.tsukinami-system.com/fastapi/schedules', this.events, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });

                //メモ
                axios.post('https://www.tsukinami-system.com/fastapi/memo',this.memo_info, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });


                //初期値を更新する
                this.original_profile_data  = JSON.parse(JSON.stringify(this.profile_data));
                this.original_homyos = JSON.parse(JSON.stringify(this.homyos));
                this.original_account_book = JSON.parse(JSON.stringify(this.account_book));
                this.original_reports = JSON.parse(JSON.stringify(this.reports));
                this.original_events = JSON.parse(JSON.stringify(this.events));
                this.original_family = JSON.parse(JSON.stringify(this.family));
                this.original_memo = JSON.parse(JSON.stringify(this.memo_info));
                
                // ボタンを変える
                this.EditModeActive = !this.EditModeActive;
            }else{ 
                const answer2 = window.confirm("編集内容を破棄して編集モードを終了しますか？")
                 if(answer2){
                    //編集内容を初期値に戻す
                    this.profile_data = JSON.parse(JSON.stringify(this.original_profile_data)); 
                    this.homyos = JSON.parse(JSON.stringify(this.original_homyos));
                    this.account_book = JSON.parse(JSON.stringify(this.original_account_book));
                    this.reports = JSON.parse(JSON.stringify(this.original_reports));
                    this.events = JSON.parse(JSON.stringify(this.original_events));
                    this.family = JSON.parse(JSON.stringify(this.original_family));
                    this.memo_info = JSON.parse(JSON.stringify(this.original_memo));
                    // ボタンを変える 
                    this.EditModeActive = !this.EditModeActive;
                 }else{
                    //何もせずに戻る
                    return
                 }
        }
        }else{
        // ボタンを変える 
        this.EditModeActive = !this.EditModeActive;
        }
       },
    
    async onExport(ext) {
        
      // ①初期化
      const workbook = new ExcelJS.Workbook(); // workbookを作成
      workbook.addWorksheet('名簿'); // worksheetを追加
      const worksheet = workbook.getWorksheet('名簿'); // 追加したworksheetを取得

      // ②データを用意
      // 各列のヘッダー
      worksheet.columns = [
        { header: 'ID', key: 'membership_number' },
        { header: '名前', key: 'name' },
        { header: '種別', key: 'member_type' },
        { header: '郵便番号', key: 'zip_code' },
        { header: '住所', key: 'address' },
        { header: '電話番号', key: 'phone_number' },
        { header: '法味', key: 'letter' },
        { header: '加入日', key: 'joined_date' },
        { header: '連絡可能', key: 'availability' },
      ];

      let member_info_data = JSON.parse(JSON.stringify(this.member_info));

      // true/falseを言葉に変換処理
      member_info_data.forEach((data) => {

        // excel csvがゼロ落ちする問題... 先頭にアポストロフィいれるべきか否か
        // 消えるのが目に見えているので一旦いれておく(日本人はエクセル好き)
        data.phone_number = "'" + data.phone_number  
       // data.phone_number = '="' + data.phone_number + '"'

        if(data.letter){
            data.letter = "送付"}else{data.letter = "未送"}
        if(data.availability){data.availability = "連絡可能"}else{data.availability = "連絡不可"}

    });

      // 各行のデータ（worksheet.columnsのkeyがオブジェクトのキーと同じになる）
      worksheet.addRows(member_info_data); // memver_infoを入れる
      let charcode = this.charcode

      // ③ファイル生成
      const uint8Array =
        ext === 'xlsx'
          ? await workbook.xlsx.writeBuffer() // xlsxの場合
          : charcode === "UTF8"
          ? await workbook.csv.writeBuffer() // csvの場合
          : new Uint8Array(
            encoding.convert(await workbook.csv.writeBuffer(), {
              from: "UTF8",
              to: "SJIS"
            })
          );

      const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      const a = document.createElement('a');
      a.href = (window.URL || window.webkitURL).createObjectURL(blob);
      a.download = `檀家名簿.${ext}`;
      a.click();
      a.remove()
    
  },

  // 報告書関連関数
  submitReport () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ if文の中に入る
            let new_id_num = this.getHashMaxNumber(this.reports, "id") + 1 //IDの中で最大の値に1を加算させる 最終的にDBでの最大値を取得する
            const formattedDate = this.date.replace(/-/g, "/");
          
            let newReport = { id: new_id_num,
                            date: formattedDate,
                            title: this.name_content,
                            dealer: this.worker,
                            content: this.content,
                            address: this.address,
                            member_id:this.member_id,
                            memo: this.note}
            this.reports.push(newReport);
            this.sortedByDates()
            this.dialog =false
            this.clearReport()
            
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
      },
      clearReport () {
        this.$refs.input_form.resetValidation()
        this.name_content= ''
        this.address =  JSON.parse(JSON.stringify(this.profile_data.address)); // 住所は初期値に戻す
        this.zip =  ''
        this.content =  ''
        this.worker =  '' 
        this.note = ''
      },
      deleteReport (id){
        var result = this.reports.filter((report) => {
            return (report.id != id);});
        this.reports = result
      },

    
      addFamilyMember () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ
          // if文の中に入る
          
          let new_family_member = { name: this.family_name, 
                                    relationship:this.family_relationship
                                  }
                      
          this.family.push(new_family_member);
          this.sortedByDates()
          this.dialog =false
          this.clearFamilyAdd()
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
      },
      clearFamilyAdd () {
        this.$refs.input_form.resetValidation()
        this.family_name= ''
        this.family_relationship =  ''
      },

      deleteFamilyMember(family_name) {

        var result = this.family.filter((family) => {
            return (family.name != family_name);});
        this.family = result

      },
    
    

    submitFamilybook () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ
          // if文の中に入る
          let new_id_num = this.getHashMaxNumber(this.homyos, "id") + 1 //IDの中で最大の値に1を加算させる 最終的にDBでの最大値を取得する
          const formattedDate = this.date.replace(/-/g, "/");
          
          let new_homyo = {             id: new_id_num,
                                        homyo: this.homyo,
                                        member_id : this.member_id,
                                        date_of_death: formattedDate,
                                        name: this.name,
                                        relationship: this.homyo_relationship,
                                        grave: this.grave_info,
                                        funeral: this.funeral_info,
                                        memo: this.deceased_person_note
                                    }
            this.homyos.push(new_homyo);
            this.sortedByDates()
            this.dialog =false
            this.clearFamilybook()
            
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
      },
      clearFamilybook () {
        this.$refs.input_form.resetValidation()
        this.homyo= ''
        this.name =  ''
        this.homyo_relationship =  ''
        this.grave_info =  '' 
        this.funeral_info = ''
        this.deceased_person_note = ''
      },
      deleteFamilybook(id){
        var result = this.homyos.filter((family) => {
            return (family.id != id);});
        this.homyos = result
      },
      
    submitAccountbook () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ if文の中に入る
                let new_id_num = this.getHashMaxNumber(this.account_book, "id") + 1 //IDの中で最大の値に1を加算させる 最終的にDBでの最大値を取得する
                const formattedDate = this.date.replace(/-/g, "/");
                const member_name = this.profile_data.house_name;

                let new_account_book={  event_id: new_id_num,
                                        member_id : this.member_id,
                                        member_name: member_name,
                                        title: this.payment_name,
                                        date: formattedDate,
                                        content: this.money_content,
                                        amount: this.money_amount,
                                        memo: this.money_note,
                                        confirmation: this.money_confirmation,}
                this.account_book.push(new_account_book);
                this.sortedByDates()
                this.dialog =false
                this.clearFamilybook()
        
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
    },
    clearAccountbook () {
        this.$refs.input_form.resetValidation()
        this.homyo= ''
        this.name =  ''
        this.homyo_relationship =  ''
        this.grave_info =  '' 
        this.funeral_info = ''
        this.deceased_person_note = ''
      },
    deleteAccountbook (id) {
        var result = this.account_book.filter((book) => {
            return (book.id != id);});
        this.account_book = result
    },

    submitServicePlan () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ if文の中に入る

            let new_id_num;
            if (this.service_plans.length === 0) {
            new_id_num = 1
            }else{
            new_id_num = this.getHashMaxNumber(this.service_plans, "id") + 1 //カレンダーIDの中で最大の値に1を加算させる
            }
            //let new_id_num = this.getHashMaxNumber(this.service_plans, "id") + 1 //IDの中で最大の値に1を加算させる 最終的にDBでの最大値を取得する

                let new_service_plan={  id: new_id_num,
                                        date: this.service_plan_date,
                                        service_type: this.service_plan_type,
                                        address: this.service_plan_address,
                                        memo:this.service_plan_memo,
                                        reflect_on_schedule: false,
                                        }
                this.service_plans.push(new_service_plan);
                this.sortedByDates()
                this.dialog =false
                this.clearServicePlan()
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
    },
    clearServicePlan () {
        this.$refs.input_form.resetValidation()
        this.service_plan_address =  JSON.parse(JSON.stringify(this.profile_data.address)); // 住所は初期値に戻す
        this.service_plan_memo =  ''
      },

    deleteServicePlan (id) {
        var result = this.service_plans.filter((service_plan) => {
            return (service_plan.id != id);});
        this.service_plans = result
    },

    submitMemo () {
        //this.$refs.input_form.validate()
          if (this.$refs.input_form.validate()) {
          // すべてのバリデーションが通過したときのみ if文の中に入る
                let new_id_num = this.getHashMaxNumber(this.memo_info, "id") + 1 //IDの中で最大の値に1を加算させる 最終的にDBでの最大値を取得する
                const formattedDate = this.date.replace(/-/g, "/");

                let new_memo={  id: new_id_num,
                                member_id:this.member_id,
                                date:  formattedDate,
                                title: this.memo_name,
                                content: this.memo_content}

                this.memo_info.push(new_memo);
                this.sortedByDates()
                this.dialog =false
                this.clearMemo()
                console.log(this.memo_info)
          }else {
            //バリエーション失敗　今は特にアラートも表示しない
          }
    },
    clearMemo () {
        this.$refs.input_form.resetValidation()
        this.memo_name= ''
        this.memo_content =  ''
    },

    deleteMemo (id) {
        var result = this.memo_info.filter((memo) => {
            return (memo.id != id);});
        this.memo_info = result
    },

      toJapaneseExsistance(bool){
        if(bool){return "有り"}else{return "無し"}
      },
      toJapaneseSendletter(bool){
        if(bool){return "送付"}else{return "未送"}
      },
      toJapaneseConfirmation(bool){
        if(bool){return "責任者確認済み"}else{return "責任者未確認"}
      },

      toAskAskReflectOnSchedule(bool){
        if(bool){return "スケジュール反映"}else{return "スケジュール未反映"}
      },

     toMakeServiceSentence(service_type, date){
        
        if(service_type=="月参り"){return "毎月"+ date + "日　" + service_type}
        if(service_type=="祥月命日"){return "毎年"+ date + "　"+ service_type}
      },

    getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
},



    send_save(){
     
      let indexNum = this.events.findIndex((e) => e.id == [this.event_id]);
      indexNum = Number(indexNum) 

      // events多次元連想配列内のidを検索ある場合のみ更新    
      if (indexNum != -1 ) {

      const formatTime = format('HH:mm:ss');
      //startDateオブジェクトに代入
      var event_start_Date = String(this.event_start_date)  + "T" + String(this.event_start_time) ;
      event_start_Date = new Date(event_start_Date);
      this.events[indexNum]["start"] =new Date(event_start_Date) 
      
      if(this.switch_start_fulldate == true && this.switch_end_fulldate == true){
        this.events[indexNum]["start_day_timed"] = false
        this.events[indexNum]["end_day_timed"] = false
        this.events[indexNum]["timed"] = false
      }else if(this.switch_start_fulldate == true && this.switch_end_fulldate == false){
        this.events[indexNum]["start_day_timed"] = false
        this.events[indexNum]["end_day_timed"] = true
        this.events[indexNum]["timed"] = false
      }else if(this.switch_start_fulldate == false && this.switch_end_fulldate == true){
        this.events[indexNum]["start_day_timed"] = true
        this.events[indexNum]["end_day_timed"] = false
        this.events[indexNum]["timed"] = false
      }else{
        this.events[indexNum]["start_day_timed"] = true
        this.events[indexNum]["end_day_timed"] = true
        this.events[indexNum]["timed"] = true
      }

      //endtDateオブジェクトに代入
      var event_end_Date = String(this.event_end_date)  + "T" + String(this.event_end_time) ;
      event_end_Date = new Date(event_end_Date);
      this.events[indexNum]["end"] =new Date(event_end_Date) 

      // データが存在した時の処理
      this.events[indexNum]["event_name"] = this.event_name // this.event_name
      this.events[indexNum]["event_opponent"] = this.event_opponent
      this.events[indexNum]["event_dealer"]= this.event_dealer
      this.events[indexNum]["event_address"] = this.event_address
      this.events[indexNum]["event_memo"] = this.event_memo
       
      var opponent = ""
      var dealer = ""
      // カレンダーの表示用　予定名　相手先　（対応者）の順で表示する
      if(this.event_opponent){
        opponent = "　" + this.event_opponent 
      }
      if(this.event_dealer){
        dealer = "　(" + this.event_dealer + ")"
      }

      this.events[indexNum]["name"] = this.event_name + opponent + dealer
     
        } else {
        // データが存在しなかった時の処理
        console.log("存在しない予定データです")
          //this.events[0]["name"] = this.event_name
          //console.log(this.events[1])
        }
   
    },
    setTodate() {
      this.value = moment().format('yyyy-MM-DD');
    },
    showEvent({ event }) {

      // トグルの表示切り替え
      if(event.start_day_timed){
      this.switch_start_fulldate = false
      }else{
        this.switch_start_fulldate = true
      }
      if(event.end_day_timed){
      this.switch_end_fulldate = false
      }else{
        this.switch_end_fulldate = true
      }

      //format用の関数を準備
      const formatdate = format('yyyy-MM-dd');
      //開始日データ
      const start_date = event.start
      const end_date = event.end

      //文字列化
      const start_dateString = formatdate(start_date);
      const end_dateString = formatdate(end_date);
      this.event_start_date = start_dateString
      this.event_end_date = end_dateString

      const formatTime = format('HH:mm:ss');
      //開始日データ
      const start_time = event.start
      const end_time = event.end

      //文字列化
      const start_timeString = formatTime(start_time);
      const end_timeString = formatTime(end_time);
      this.event_start_time = start_timeString
      this.event_end_time = end_timeString

      this.event_name = event.event_name
      this.event_opponent = event.event_opponent
      this.event_dealer = event.event_dealer
      this.event_address = event.event_address
      this.event_memo = event.event_memo
      this.event_id = event.id //イベントIDを変数化
    // window.open('http://localhost:8080/app/forms/scheduleinfo'+ '?eventid='+ eventid , '_blank'); // 新しいタブを開き、ページを表示
      this.dialog_type= 'schedule_info'

     this.dialog = true
    },
    jumptoReport(){
      //報告書画面に遷移
      window.open('http://localhost:8080/app/forms/workform'+ '?eventid='+ this.event_id, '_blank'); // 新しいタブを開き、ページを表示

    },
    viewDay({ date }) {
      this.type = "day"

    },
    jumptoMap(){

      // GoogleMapに遷移
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.event_address, '_blank');


    },
    remove () {

        let indexNum = this.events.findIndex((e) => e.event_id == [this.event_id]);
        indexNum = Number(indexNum) 
        console.log(indexNum)

        // console.log(worker_info.member_id)
        const index = this.events.indexOf(indexNum)
        const answer = window.confirm("この予定を削除してもよろしいでしょうか？")
        if(answer){
        this.events.splice(indexNum , 1)
        }else{ return }
},     


    getEventColor(event) {
      return event.color;
    },

    startTime (tms) {

       if(!this.EditModeActive){
        // 編集モードがON担っていない場合はすぐreturn
        return
       }
      
       const mouse = this.toTime(tms)


        if (this.dragEvent && this.dragTime === null) {
          return 
        } else {
          this.createStart = this.roundTime(mouse)
          let colors= ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575']
          
          let new_id_num;
          if (this.events.length === 0) {
          new_id_num = 1
          }else{
          new_id_num = this.getHashMaxNumber(this.events, "event_id") + 1 //カレンダーIDの中で最大の値に1を加算させる
          }
          
          this.createEvent = {
         
              event_id: new_id_num, //新規追加の場合でもデータ紐づけ用にIDは振る　これはプライマリキーなので、今後は別のイベントIDを持つ
              unsaved: true, //新規追加フラグ
              name: '新しい予定',
              event_name:'新しい予定',
              opponent_member_id: this.member_id,
              event_opponent: this.profile_data.house_name,
              event_dealer: '',
              event_address:'',
              event_memo:'',
              start:  this.createStart,
              end: this.createStart,
              color: '#3F51B5',
              timed: true,
              start_day_timed: true,
              end_day_timed: true
          }

          this.events.push(this.createEvent)
        }
        
      },
        toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute ).getTime()
      },
      
      roundTime (time, down = true) {
        const roundTo = 10 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },

        rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      startDrag ({ event, timed }) {
        //必須処理
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          
        }
      },

    mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      endDrag () {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
      }, 


      getHashMaxNumber(hash, keyName) {
	    var maxNumber;
      for (var i = 0, len = hash.length; i < len; i++) {
        if (!hash[i][keyName]) continue;
        if (typeof maxNumber === 'undefined' || hash[i][keyName] > maxNumber) {
          maxNumber = hash[i][keyName];}}
        return maxNumber;
      },


    isEqualObject () {

        // 連想配列の中身が一致しているか確認するメソッド
        let old_array = JSON.parse(JSON.stringify(this.original_profile_data));
        let new_array = JSON.parse(JSON.stringify(this.profile_data)); 
        
        if(Object.keys(old_array).length ==  Object.keys(new_array).length ){
            //数が一致しているかをまず確認

            for(let i = 0; i < Object.keys(new_array).length; i++){
                

                for (let key in new_array) {
                    //console.log('key:' + key + ' value:' + new_array[key]);
                    //console.log('key:' + key + ' value:' + old_array[key]);

                    if(new_array[key] != old_array[key]){
                        // 中身が異なる
                        return false

                    }else{
                        continue;
                    }
                    }
                }
            return true
            
        }else{
            // 連想配列の数が異なる
            return false
             }
    },
    toJapaneseCallable(bool){
        if(bool){return "連絡可能"}else{return "連絡不可"}
      },

    isEqualArray (old_val, new_val) {
      // 連想配列の中身が一致しているか確認するメソッド
        let old_array = JSON.parse(JSON.stringify(old_val)); 
        
        let new_array = JSON.parse(JSON.stringify(new_val)); 

        if(new_array.length ==  old_array.length){
            //数が一致しているかをまず確認

            for(let i = 0; i < new_array.length; i++){
                
                let new_line = new_array.slice(i, i+1)
                let old_line = old_array.slice(i, i+1)

                new_line = new_line[0]
                old_line = old_line[0]
                

                for (let key in new_line) {
                    if(new_line[key] != old_line[key]){
                        // 中身が異なる
                        return false
                    }else{
                        continue;
                    }
                    }
        } return true
            
        }else{
            // 連想配列の数が異なる
            return false
             }
    },
    planed_service_to_schedule_old(){

        if(this.service_plans){

            const todate = new Date()
            const formatMonth = format('yyyy/MM');
            const formatYear = format('yyyy');
            
        let service_plan_array = JSON.parse(JSON.stringify(this.service_plans)); 

            for(let i = 0; i < service_plan_array.length; i++){
                
                let service_plan_line = service_plan_array.slice(i, i+1)
                //console.log(service_plan_line[0].service_type)
                if(service_plan_line[0].service_type == "月参り"){
                    var ConvertedMonth = formatMonth(todate) 
                    
                    ConvertedMonth = ConvertedMonth  + "/" + service_plan_line[0].date
                    ConvertedMonth  =new Date(ConvertedMonth)

                
                    let month = 1;
                    while (month < 120) {
                        
                        //10年間分だけ追加 12ヵ月×10年=120回
                        let baseDate  =new Date(ConvertedMonth) // ここで初期化
                        let date = baseDate.setMonth(baseDate.getMonth() + month)
                        let new_id_num = this.getHashMaxNumber(this.events, "id") + 1 

                        const service_address = service_plan_line[0].address
                        const service_memo = service_plan_line[0].memo

                        // let new_event= { 
                        //         id: new_id_num,
                        //         name: "月参り　" + this.profile_data.house_name,                 
                        //         event_name: "月参り",
                        //         event_opponent: this.profile_data.house_name,
                        //         event_dealer: '',
                        //         event_address: service_address,
                        //         event_memo: service_memo,
                        //         start: date,
                        //         end: date ,
                        //         color: 'green',
                        //         timed: false,
                        //         start_day_timed: false,
                        //         end_day_timed: false}
                        let new_event= { 
                        event_id: new_id_num, //新規追加の場合でもデータ紐づけ用にIDは振る　これはプライマリキーなので、今後は別のイベントIDを持つ
                        unsaved: true, //新規追加フラグ
                        name:  "月参り　" + this.profile_data.house_name,
                        event_name: "月参り",
                        opponent_member_id: this.member_id,
                        event_opponent: this.profile_data.house_name,
                        event_dealer: '',
                        event_address: service_address,
                        event_memo: service_memo,
                        start:  this.createStart,
                        end: this.createStart,
                        color: '#3F51B5',
                        timed: true,
                        start_day_timed: true,
                        end_day_timed: true}
                        


                        this.events.push(new_event)
                        month += 1;
                    }
                    console.log(this.events)
                }else if(service_plan_line[0].service_type == "祥月命日"){
                    var ConvertedYear = formatYear(todate)

                    ConvertedYear = ConvertedYear  + "/" + service_plan_line[0].date
                    ConvertedYear  =new Date(ConvertedYear)

                    const service_address = service_plan_line[0].address
                    const service_memo = service_plan_line[0].memo
                    let new_id_num = this.getHashMaxNumber(this.events, "id") + 1 //カレンダーIDの中で最大の値に1を加算させる

                
                    let year = 1;
                    while (year < 101) {
                        
                        //百周忌まで追加
                        let baseDate  =new Date(ConvertedYear) // ここで初期化
                        let date = baseDate.setFullYear(baseDate.getFullYear() + year)
                    
                        // let new_event= { 
                        //         id: new_id_num,
                        //         name: "祥月命日　" + this.profile_data.house_name,                 
                        //         event_name: "祥月命日",
                        //         event_opponent: this.profile_data.house_name,
                        //         event_dealer: '',
                        //         event_address: service_address,
                        //         event_memo: service_memo,
                        //         start: date,
                        //         end: date ,
                        //         color: 'info',
                        //         timed: false,
                        //         start_day_timed: false,
                        //         end_day_timed: false}
                        
                        let new_event= { 
                        event_id: new_id_num, //新規追加の場合でもデータ紐づけ用にIDは振る　これはプライマリキーなので、今後は別のイベントIDを持つ
                        unsaved: true, //新規追加フラグ
                        name: "祥月命日　" + this.profile_data.house_name,  
                        event_name: "祥月命日",
                        opponent_member_id: this.member_id,
                        event_opponent: this.profile_data.house_name,
                        event_dealer: '',
                        event_address: service_address,
                        event_memo: service_memo,
                        start:  this.createStart,
                        end: this.createStart,
                        color: '#3F51B5',
                        timed: true,
                        start_day_timed: true,
                        end_day_timed: true}
                        this.events.push(new_event)
                        year += 1;}
                    }}}else{return}
    },
    getEvents(member_id) {



    let user_info = localStorage.getItem('userInfo')
    user_info = JSON.parse(user_info)
    let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}


    axios.get('https://www.tsukinami-system.com/fastapi/schedule/?id=' + member_id, config)
    .then(response => {
    
    // 成功時の処理etc
    let event_data = response.data
    let event_list = new Array();

    for(let i = 0; i < event_data.length; i++){
        console.log(i)

        let title = event_data[i].title
        let opponent  = event_data[i].opponent
        let responder = event_data[i].responder
        
        //カレンダーに表示されるタイトルを作成
        if(opponent){
        title = title + "　" + opponent
        }
        if(responder){
        title = title + "　(" + responder + ")"
        }

        let event={
        id: event_data[i].id,
        event_id: event_data[i].event_id,
        name: title,
        unsaved: false,                
        event_name:event_data[i].title,
        opponent_member_id: event_data[i].opponent_member_id,
        event_opponent: event_data[i].opponent,
        event_dealer: event_data[i].responder,
        event_address:event_data[i].address,
        event_memo:event_data[i].memo,
        start: new Date(event_data[i].start_date),
        end: new Date(event_data[i].end_date),
        color: 'green',
        timed: event_data[i].timed,
        start_day_timed: event_data[i].start_day_timed,
        end_day_timed: event_data[i].end_day_timed
    }
    event_list.push(event)
    }
    this.events = event_list
    //ここでデータ保持
    this.original_val = JSON.parse(JSON.stringify(this.events));      
    return response
    })
    .catch(error => {
    // 失敗時の処理etc
    alert(error)
    return error
    })
    },

    getUserInfo(id) {

        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

        axios.get('https://www.tsukinami-system.com/fastapi/report_info?id=' + id, config )
        .then(response => {
        console.log(response.data)

         // 成功時の処理etc
        let report_data = response.data
        let report_list = new Array();

        for(let i = 0; i < report_data.length; i++){
            
            let report ={
            id: report_data[i].id,              
            member_id:report_data[i].member_id,
            title: report_data[i].title,
            dealer: report_data[i].dealer,
            content: report_data[i].content,
            address:report_data[i].address,
            date: new Date(report_data[i].date).toLocaleDateString(),
            memo: report_data[i].memo
            }
            report_list.push(report);
        }
        this.reports = report_list
        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })

        
        //   法名
        axios.get('https://www.tsukinami-system.com/fastapi/homyo_info?id=' + id, config )
        .then(response => {
        
                
        let homyo_data = response.data
        let homyo_list = new Array();
        for(let i = 0; i < homyo_data.length; i++){
            let homyo ={
            id: homyo_data[i].id,              
            member_id:homyo_data[i].member_id,
            homyo: homyo_data[i].homyo,
            name: homyo_data[i].name,
            relationship: homyo_data[i].relationship,
            grave :homyo_data[i].grave_info,
            funeral :homyo_data[i].funeral_info,
            date_of_death: new Date(homyo_data[i].date).toLocaleDateString(),
            memo: homyo_data[i].memo
            }
            homyo_list.push(homyo);
        }
        this.homyos = homyo_list

       

        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
        
       
        // 個別帳簿
        axios.get('https://www.tsukinami-system.com/fastapi/account_book_info?id=' + id, config )
        .then(response => {

        console.log(response.data)
        let account_book_data = response.data

        let account_book_list = new Array();

        for(let i = 0; i < account_book_data.length; i++){
            
            let book ={
            event_id: account_book_data[i].id,              
            member_id:account_book_data[i].member_id,
            member_name:account_book_data[i].member_name,
            title: account_book_data[i].title,
            content: account_book_data[i].content,
            amount:account_book_data[i].amount,
            date: new Date(account_book_data[i].date).toLocaleDateString(),
            memo: account_book_data[i].memo,
            confirmation : account_book_data[i].confirmation
            }
            account_book_list.push(book);
        }

        this.account_book = account_book_list
        console.log(this.account_book)
        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
        
        // メモ
        axios.get('https://www.tsukinami-system.com/fastapi/memo_info?id=' + id, config )
        .then(response => {
        console.log(response.data)
            
        let memo_data = response.data
        let memo_list = new Array();
        for(let i = 0; i < memo_data.length; i++){
            let memo ={
            id: memo_data[i].id,              
            member_id:memo_data[i].member_id,
            title: memo_data[i].title,
            content: memo_data[i].content,
            date: new Date(memo_data[i].date).toLocaleDateString(),
            }
            memo_list.push(memo);
        }
        this.memo_info = memo_list
        console.log(this.memo_info)

        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
        
        //ここでデータ保持
        this.original_profile_data  = JSON.parse(JSON.stringify(this.profile_data));
        this.original_homyos = JSON.parse(JSON.stringify(this.homyos));
        this.original_account_book = JSON.parse(JSON.stringify(this.account_book));
        this.original_reports = JSON.parse(JSON.stringify(this.reports));
        this.original_events = JSON.parse(JSON.stringify(this.events));
        this.original_family = JSON.parse(JSON.stringify(this.family));
        this.original_memo = JSON.parse(JSON.stringify(this.memo_info));
                

  
        },

    planed_service_to_schedule(service_plan){
        console.log("kiata")
        if(service_plan){

            var answer = true;
            if(service_plan.reflect_on_schedule){answer = window.confirm("こちらの予定は既に追加済みですが、再度追加してもよろしいでしょうか？")}

            if(answer){
                console.log("kiata1")
                let indexNum = this.service_plans.findIndex((e) => e.id == [service_plan.id]);
                indexNum = Number(indexNum) 
                // service_plans多次元連想配列内のidを検索ある場合のみ更新    
                if (indexNum != -1 ) { 
                    console.log("kiata2")
                
                    this.service_plans[indexNum]["reflect_on_schedule"] = true

                    const todate = new Date()
                    const formatMonth = format('yyyy/MM');
                    const formatYear = format('yyyy');
                    
                        
                        //console.log(service_plan_line[0].service_type)
                        if(service_plan.service_type == "月参り"){
                            var ConvertedMonth = formatMonth(todate) 
                            
                            ConvertedMonth = ConvertedMonth  + "/" + service_plan.date
                            ConvertedMonth  =new Date(ConvertedMonth)

                            let month = 0;
                            while (month < 120) {
                                
                                //10年間分だけ追加 12ヵ月×10年=120回
                                let baseDate  =new Date(ConvertedMonth) // ここで初期化
                                let date = baseDate.setMonth(baseDate.getMonth() + month)
                                let new_id_num = this.getHashMaxNumber(this.events, "id") + 1 //カレンダーIDの中で最大の値に1を加算させる

                                const service_address = service_plan.address
                                const service_memo = service_plan.memo
                                

                                let new_event= { 
                                        event_id: new_id_num,
                                        unsaved: true, //新規追加フラグ
                                        name: "月参り　" + this.profile_data.house_name,                 
                                        event_name: "月参り",
                                        opponent_member_id: this.member_id,
                                        event_opponent: this.profile_data.house_name,
                                        event_dealer: '',
                                        event_address: service_address,
                                        event_memo: service_memo,
                                        start: date,
                                        end: date ,
                                        color: 'green',
                                        timed: false,
                                        start_day_timed: false,
                                        end_day_timed: false}
                                this.events.push(new_event)

                                month += 1;
                            }
                        }else if(service_plan.service_type == "祥月命日"){
                            var ConvertedYear = formatYear(todate)

                            ConvertedYear = ConvertedYear  + "/" + service_plan.date
                            ConvertedYear  =new Date(ConvertedYear)

                            const service_address = service_plan.address
                            const service_memo = service_plan.memo
                            let new_id_num = this.getHashMaxNumber(this.events, "id") + 1 //カレンダーIDの中で最大の値に1を加算させる

                        
                            let year = 0;
                            while (year < 101) {
                                
                                //百周忌まで追加
                                let baseDate  =new Date(ConvertedYear) // ここで初期化
                                let date = baseDate.setFullYear(baseDate.getFullYear() + year)
                            
                                let new_event= { 
                                        event_id: new_id_num,
                                        unsaved: true, //新規追加フラグ
                                        name: "祥月命日　" + this.profile_data.house_name,                 
                                        event_name: "祥月命日",
                                        event_opponent: this.profile_data.house_name,
                                        opponent_member_id: this.member_id,
                                        event_dealer: '',
                                        event_address: service_address,
                                        event_memo: service_memo,
                                        start: date,
                                        end: date ,
                                        color: 'info',
                                        timed: false,
                                        start_day_timed: false,
                                        end_day_timed: false}
                                this.events.push(new_event)
                                year += 1;}
                            }}}else{return} //追加しないを選んだ場合
                        }else{return}
        },



    


    

    
    
}}
