//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EInfoCard from '@/components/card/BasicInfoCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account Security'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            imgOne: require('@/assets/images/svg/ui.svg'),
            imgTwo: require('@/assets/images/svg/log-in.svg'),
            imgThree: require('@/assets/images/svg/contact.svg'),
            items: [
                {
                    title: 'Password',
                    titleTwo: 'Last changed Sep 4, 2018'
                },

                {
                    title: 'Use your phone to sign in',
                    iconTwo: 'mdi-minus-circle',
                    titleTwo: 'Paused',
                    iconColor: 'secondary'
                },
                {
                    title: '2-Step verification',
                    iconTwo: 'mdi-check-circle',
                    titleTwo: 'On',
                    iconColor: 'primary'
                }
            ],
            items2: [
                {
                    title: 'Recovery phone',
                    titleTwo: '+990 3533 990 89'
                },

                {
                    title: 'Recovery email',

                    titleTwo: 'ui-lib@gmail.com'
                }
            ],
            accordion: [
                {
                    img: require('@/assets/images/svg/google-logo.svg'),
                    title: 'Signing in with Google',
                    subtitle:
                        'You use your Google Account to sign in to 22 sites and apps'
                },
                {
                    img: require('@/assets/images/svg/password-manager.svg'),
                    title: 'Password Manager',
                    subtitle:
                        'You have 54 passwords saved in your Google Account. Password Manager makes it easier to sign in to sites and apps you use on any signed-in device.'
                },
                {
                    img: require('@/assets/images/svg/link.svg'),
                    title: 'Linked Accounts',
                    subtitle:
                        'You have no linked accounts. You can give Google access to data from your third-party sites and apps, like your playlists.'
                }
            ]
        }
    }
}
