//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EInfoCard from '@/components/card/BasicInfoCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account Personal Info'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            imgOne: require('@/assets/images/svg/search.svg'),
            accordion: [
                {
                    title: 'photo',
                    subtitle: 'A photo helps personalize your account'
                },
                {
                    title: 'name',
                    subtitle: 'UI Lib'
                },
                {
                    title: 'birthday',
                    subtitle: 'March 18, 1990'
                },
                {
                    title: 'Gender',
                    subtitle: 'Male'
                },
                {
                    title: 'password',
                    subtitle: 'Last changed Sep 4, 2018'
                }
            ],
            accordion2: [
                {
                    title: 'email',
                    subtitle: 'ui-lib23@gmail.com'
                },
                {
                    title: 'phone',
                    subtitle: '+1-202-555-0108'
                }
            ]
        }
    }
}
