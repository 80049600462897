//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EInfoCard from '@/components/card/BasicInfoCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account Data'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            imgOne: require('@/assets/images/svg/ui.svg'),
            imgTwo: require('@/assets/images/svg/business-and-finance.svg'),
            imgThree: require('@/assets/images/svg/database.svg'),
            imgFour: require('@/assets/images/svg/wrench.svg'),
            imgFive: require('@/assets/images/svg/calendar.svg'),
            imgSix: require('@/assets/images/svg/architecture-and-city.svg'),
            items: [
                {
                    icon: 'mdi-reload-alert',
                    title: 'Web & App Activity',
                    iconTwo: 'mdi-check-circle',
                    titleTwo: 'On',
                    iconColor: 'primary'
                },

                {
                    icon: 'mdi-map-marker-alert',
                    title: 'Location & History',
                    iconTwo: 'mdi-pause-circle',
                    titleTwo: 'Paused',
                    iconColor: 'success'
                },
                {
                    icon: 'mdi-youtube',
                    title: 'Youtube History',
                    iconTwo: 'mdi-check-circle',
                    titleTwo: 'On',
                    iconColor: 'danger'
                }
            ],
            items2: [
                {
                    icon: require('@/assets/images/svg/activity.svg'),
                    title: 'My Activity',
                    subtitle:
                        'Rediscover the things you’ve searched for, read, and watched'
                },
                {
                    icon: require('@/assets/images/svg/timeline.svg'),
                    title: 'Timeline',
                    subtitle:
                        'See where you have been based on your Location History'
                }
            ],
            items3: [
                {
                    img: require('@/assets/images/svg/youtube.svg'),
                    title: 'Youtube'
                },
                {
                    img: require('@/assets/images/svg/art-and-design.svg'),
                    title: 'Search Activity'
                },
                {
                    img: require('@/assets/images/svg/mail.svg'),
                    title: 'Mail'
                },
                {
                    img: require('@/assets/images/svg/calendar.svg'),
                    title: 'calendar'
                },
                {
                    img: require('@/assets/images/svg/seo-and-web.svg'),
                    title: 'Drive'
                },
                {
                    img: require('@/assets/images/svg/google-photos.svg'),
                    title: 'Photo'
                },
                {
                    img: require('@/assets/images/svg/maps-and-location.svg'),
                    title: 'Maps'
                }
            ],
            accordion: [
                {
                    img: require('@/assets/images/svg/download.svg'),
                    title: 'Download your data',
                    subtitle:
                        'Make a copy of your data to use it with another account or service'
                },
                {
                    img: require('@/assets/images/svg/login.svg'),
                    title: 'Make a plan for you account',
                    subtitle:
                        'Use Inactive Account Manager to plan what happens to your data if you stop using your account'
                },
                {
                    img: require('@/assets/images/svg/remove-user.svg'),
                    title: 'Delete a service or your account',
                    subtitle:
                        'You can do this if you no longer use a service or your account'
                }
            ],
            accordion2: [
                {
                    img: 'mdi-globe-model',
                    title: 'Language',
                    subtitle: 'English (United States)'
                },
                {
                    img: 'mdi-keyboard-outline',
                    title: 'Input Tools',
                    subtitle: 'Type more easily in your language'
                },
                {
                    img: 'mdi-account-tie-outline',
                    title: 'Accessibility',
                    subtitle: 'Screen reader OFF High-contrast colors OFF'
                },
                {
                    img: 'mdi-account-search-outline',
                    title: 'Search Settings',
                    subtitle: 'Settings like private results and safe search '
                }
            ],
            accordion3: [
                {
                    icon: 'mdi-briefcase-outline',
                    title: 'Business Personalization',
                    iconTwo: 'mdi-check-circle',
                    titleTwo: 'On',
                    iconColor: 'primary'
                },
                {
                    icon: 'mdi-warehouse',
                    title: 'Your business',
                    iconTwo: 'mdi-pause-circle',
                    titleTwo: 'Pause',
                    iconColor: 'dark'
                }
            ]
        }
    }
}
