//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import UserDrawer from "../common-drawer/UserDrawer.vue";
import NotificationDrawer from "../common-drawer/NotificationDrawer.vue";
import SearchDrawer from "../common-drawer/SearchDrawer.vue";
export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer,
    NotificationDrawer,
    SearchDrawer,
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
    };
  },
  methods: {
    ...mapActions(["changeVerticalSaasSidebarDrawer"]),
    toggleVerticalSaasSidebarDrawer() {
      this.changeVerticalSaasSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
  },
};
