//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
export default {
    name: 'CartDrawer',
    props: {},
    data() {
        return {
            totalPrice: ''
        }
    },
    computed: {
        ...mapGetters(['getCartList'])
    },
    methods: {
        ...mapActions(['addCart']),
        totalAmount() {
            let total = 0
            return (total += this.getCartList.reduce(
                (left, cur) => left + cur.price * cur.qty,
                0
            ))
        },
        deleteCart(cart) {
            this.getCartList.splice(this.getCartList.indexOf(cart), 1)
        },
        productDecrement(product) {
            if (product.qty <= 1) {
                product.qty = 0
                this.getCartList.splice(this.getCartList.indexOf(product), 1)
            } else {
                product.qty--
            }
        }
    }
}
