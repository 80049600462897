//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'ComboBox'
    },
    data() {
        return {
            select: ['Vuetify', 'Programming'],
            itemsOne: ['Programming', 'Design', 'Vue', 'Vuetify'],
            // advanced custom options
            activator: null,
            attach: null,
            colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
            editing: null,
            index: -1,
            items: [
                {header: 'Select an option or create one'},
                {
                    text: 'Foo',
                    color: 'blue'
                },
                {
                    text: 'Bar',
                    color: 'red'
                }
            ],
            nonce: 1,
            menu: false,
            model: [
                {
                    text: 'Foo',
                    color: 'blue'
                }
            ],
            x: 0,
            search: null,
            y: 0
        }
    },
    watch: {
        model(val, prev) {
            if (val.length === prev.length) return

            this.model = val.map((v) => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                        color: this.colors[this.nonce - 1]
                    }

                    this.items.push(v)

                    this.nonce++
                }

                return v
            })
        }
    },

    methods: {
        edit(index, item) {
            if (!this.editing) {
                this.editing = item
                this.index = index
            } else {
                this.editing = null
                this.index = -1
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false

            const hasValue = (val) => (val != null ? val : '')

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        }
    }
}
