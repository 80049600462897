//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Pricing Ver 1",
  },
  data() {
    return {
      priceList: [
        {
          icon: "mdi-account",
          iconColor: "danger",
          title: "Student",
          btnColor: "danger",
          supColor: "text-danger",
          domain: 1,
          users: 5,
          copies: 10,
          price: 20,
        },
        {
          icon: "mdi-rocket-launch-outline",
          iconColor: "success",
          title: "Basic Plan",
          btnColor: "success",
          supColor: "text-success",
          domain: 8,
          users: 15,
          copies: 100,
          price: 75,
        },
        {
          icon: "mdi-account-tie",
          iconColor: "orange",
          title: "For Business",
          btnColor: "orange",
          supColor: "text-orange",
          domain: 8,
          users: 15,
          copies: 100,
          price: 175,
        },
        {
          icon: "mdi-office-building",
          iconColor: "primary",
          title: "Enterprise",
          btnColor: "primary",
          supColor: "text-primary",
          domain: 8,
          users: 15,
          copies: 100,
          price: 475,
        },
      ],
    };
  },
};
