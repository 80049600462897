//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase/app'
import {mapGetters, mapActions} from 'vuex'
import axios from 'axios';

export default {
    name: 'login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignIn Two'
    },
    data() {
        return {
            show: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            //email: 'admin@gmail.com',
            email: '',
            ePassword: '',
            loading: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            nameRules: [
                (v) => !!v || 'Password is required',
               
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    methods: {

        login: function() {
           
           // axios.post('https://www.tsukinami-system.com/fastapi/login', {
            //     //username: this.email ,
            //     email: this.email ,
            //     password: this.ePassword
            // })
            // .then(response => {
            //   //ここでローカルストレージにデータを保管する
            //   let data = response.data;
            //   const userInfo = {uid:data.uid, username:data.username, 
            //                     administrator:data.administrator, access_token:data.access_token }
            //         localStorage.setItem('userInfo', JSON.stringify(userInfo))
            //         this.$router.push('/')
            // //   console.log(response);
            // //     localStorage.setItem("userInfo", JSON.stringify(response.data.access_token));
            // //     this.$router.push('/');

            // })
            // .catch(error => {
            //    console.log(error);
            // })

    
            
	var poolData = {
		UserPoolId: 'ap-northeast-1_MFtCCf7QQ', // Your user pool id here
		ClientId: '6mp2acp0lfs7uulfh74jgje9kv', // Your client id here
	};
    const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
  
    var username = this.email;
    var password = this.ePassword;

	var authenticationData = {
		Username: username,
		Password: password,
	};

	var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
		authenticationData
	);
	var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool,
	};


	var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
	cognitoUser.authenticateUser(authenticationDetails, {
		onSuccess: (result) => {
            var idToken = result.getIdToken().getJwtToken();          // IDトークン
			var accessToken = result.getAccessToken().getJwtToken();  // アクセストークン
            var refreshToken = result.getRefreshToken().getToken();   // 更新トークン
            
            console.log("idToken : " + idToken);
            console.log("accessToken : " + accessToken);
            console.log("refreshToken : " + refreshToken);

            const userInfo = {uid:1, idtoken: idToken, access_token:accessToken, refresh_token:refreshToken}
            localStorage.setItem('userInfo', JSON.stringify(userInfo))

           //alert("成功")
           this.$router.push('/');
          
		},

		onFailure: (err) => {
			alert(err.message || JSON.stringify(err));
		},
	
    }
    
    );

            

            // axios.post('https://tsukinami-system.com:8081/login', {
            //     username: this.email ,
            //     password: this.ePassword
            // })
            // .then(function (response)  => {
            //     //alert(response.data.access_token)
            //     console.log(response);
            //     localStorage.setItem("userInfo", JSON.stringify(response.data.access_token));
            //     this.$router.push('/');
            // })
            // .catch(function (error)=> {
            //     console.log(error);
            // });

            // this.axios.get('https://tsukinami-system.com:8081/login')
            // .then((response) => {
            //     alert(response.data);
            // })
            // .catch((e) => {
            //     alert(e);
            // });
            
            // // 超簡易認証
            // if(this.email == "Guzeiji@gmail.com" && this.ePassword == this.password){
            //               const newUser = { uid: 1111};
            //               localStorage.setItem("userInfo", JSON.stringify(newUser));
            //               this.$router.push('/');

            // }


        },


        //...mapActions(['login']),
        // login: function() {
        //   firebase.auth().signInWithEmailAndPassword(this.email, this.ePassword)
        //     .then(
        //       user => {
        //         // console.log(user);
        //         this.loading = true;
        //         this.$router.push('/');

        //       },
        //       err => {

        //         // alert(err);

        //       }
        //     )
        // },
        formSubmit() {
            this.login({email: this.email, password: this.ePassword})
        },
        googleSignIn() {
            const provider = new firebase.auth.GoogleAuthProvider()

            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    const newUser = {uid: result.user.uid}
                    localStorage.setItem('userInfo', JSON.stringify(newUser))
                    this.$router.push('/')
                })
                .catch((err) => {
                    alert('Oops' + err.message)
                })
        }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.uid && val.uid.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('logged in successfully ')
                this.loading = true
                setTimeout(() => {
                    this.$router.push('/')
                }, 500)
            }
        },
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
}
