//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import encoding from "encoding-japanese";
import csvToJson from "convert-csv-json";
import ExcelJS from 'exceljs';
import axios from 'axios';
var Vue = require('vue')

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: '檀家・門徒名簿'
    },
    created () {

    //ヘッダー入れる処理
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
    this.selectedtypes = this.type_collection;
    window.addEventListener("beforeunload", this.confirmSave);
  },
    destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
    mounted() {
        this.getEvents() 
        // //eventsの初期値入れとく処理
        // this.original_val = JSON.parse(JSON.stringify(this.events));
  },
  computed: {
    //Done to get the ordered headers
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
   
  },
    data() {
        return {
        items: ["Anton", "Andrey", "Sasha", "Vladimir", "Dima"],

        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        dialog_type: '',
            search: '',
            dialog: false,
            selected: [],
            headers: [],
            headersMap: [
                
                {text: '予定名',value: 'name'},
                {text: '相手先', value: 'event_opponent'},
                {text: '対応者', value: 'event_dealer'},
                {text: '住所', value: 'event_address'},
                {text: '開始日', value: 'start_day'},
                {text: '終了日', value: 'end_day'},
                {text: '開始時刻', value: 'start_time'},
                {text: '終了時刻', value: 'end_time'},
                {text: '終日', value: 'timed'},
                {text: '連絡事項', value: 'event_memo'},
                {text: '編集', value: 'action'}
            ],
            new_member_name:"",
            new_member_type:"",
            new_member_zip_code:"",
            new_member_address:"",
            new_member_phone_number:"", 
            new_member_letter:false,
            new_member_caretaker:"",
            new_member_contactable:false,
            new_member_start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu:"",
            // 入力規則
            required: value => !!value || "必ず入力してください", // 入力必須の制約
            EditModeActive: false,
            charcode: "UTF8", //デフォルト文字コード
            selectedHeaders: [],
            type_collection:["檀家", "門徒",  "信徒", "一般", "離檀"],
            selectedtypes: [],
            original_val:[], // 初期値を入れとく変数
            upload_file:[], //uploadしたfile
            events: [
           
            ],
            
        }
    },

/*  値が編集されるのを監視するプログラム.. 現在は不要と判断
    watch: {
      events: {
        handler: function (val, oldVal) {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = '変更中'
        },
        deep: true
      },
    },
*/
    beforeRouteLeave (to, from , next) {
        
            const Equality = this.isEqualArray()
            //console.log(Equality)

            if(Equality == false){
               
                const answer = window.confirm('新たに編集された門徒名簿のデータは保存されていません。未保存のまま門徒名簿を閉じてもよろしいでしょうか？')

                if (answer) {  next() }else{ next(false) }
            }else{next()}
},



methods: {
      jump (events) {
        // alert(events.id)
         window.open('https://www.tsukinami-system.com/app/forms/workform?id='+ events.event_id, '_blank'); // 新しいタブを開き、ページを表示　
        
      },
      remove (events) {

         console.log(events.member_id)
         const index = this.events.indexOf(events)
         const answer = window.confirm(events.name + "さんを名簿から削除してもよろしいでしょうか？")
         if(answer){
            this.events.splice(index, 1)
         }else{ return }
      },     

       active: function () {
        let Equality = this.isEqualArray()

        // 編集完了し、編集内容を保存するか確認 
        if(this.EditModeActive == true && Equality == false){

            const answer1 = window.confirm("編集内容を保存してもよろしいでしょうか？")
            if(answer1){

                let user_info = localStorage.getItem('userInfo')
                user_info = JSON.parse(user_info)
                let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

                //DB編集APIを投げる
                console.log(this.events)
                axios.post('https://www.tsukinami-system.com/fastapi/members',this.events, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });
            
                //eventsの初期値を更新する
                this.original_val = JSON.parse(JSON.stringify(this.events));
                
                // ボタンを変える
                this.EditModeActive = !this.EditModeActive;
            }else{ 
                const answer2 = window.confirm("編集内容を破棄して編集モードを終了しますか？")
                 if(answer2){
                    //編集内容を初期値に戻す
                    this.events = JSON.parse(JSON.stringify(this.original_val)); 
                    // ボタンを変える 
                    this.EditModeActive = !this.EditModeActive;
                 }else{
                    //何もせずに戻る
                    return
                 }
        }
        }else{
        // ボタンを変える 
        this.EditModeActive = !this.EditModeActive;
        }
       }
    ,

      save () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = '編集モード終了'
      },
       
      open () {
        this.snack = true
        this.snackColor = 'purple'
        this.snackText = '編集モード開始'
      },
    
      
      clickcontactable (item) {
     // ボタンを押下でtrue,falseを変える処理
      if(item.contactable){
      item.contactable = false
      }else{
      item.contactable = true

      }
    },
    clickletter (item) {
     // ボタンを押下でtrue,falseを変える処理
      if(item.letter){
      item.letter = false
      }else{
      item.letter = true

      }
    },
    clicktype(item){
     // ボタンを押下でtrue,falseを変える処理
      
      var type_array = JSON.parse(JSON.stringify(this.selectedtypes));
      type_array.push("その他")

      var length_type = type_array.length
      console.log(type_array)

      if(length_type < 2){
        return false // その他のみ
      }else{

        var position = type_array.indexOf(item.type) 

      if( position == length_type-1){
        let first_element = type_array.slice(0, 1)
        item.type = first_element[0]
        console.log(item.type)
      }else{
        let next_element = type_array.slice(position+1, position+2)
        item.type = next_element[0]
        console.log(item.type)
      }}
    },
      isEqualArray () {
      // 連想配列の中身が一致しているか確認するメソッド
        let old_array = this.original_val
        let new_array = this.events 

        if(new_array.length ==  old_array.length){
            //数が一致しているかをまず確認
            for(let i = 0; i < new_array.length; i++){ 
                let new_line = new_array.slice(i, i+1)
                let old_line = old_array.slice(i, i+1)
                new_line = new_line[0]
                
                old_line = old_line[0]
                for (let key in new_line) {
                    //console.log('key:' + key + ' value:' + new_line[key]);
                    if(new_line[key] != old_line[key]){
                        // 中身が異なる
                        return false
                    }else{
                        continue; }}
        } return true
        }else{
            // 連想配列の数が異なる
            return false
             }
    },

    async onExport(ext) {
      let items = this.$refs.dataTable.$children[0].filteredItems;
      let sorted = this.$refs.dataTable.$children[0].sortItems(items);
      let datatable = sorted;
      
      // ①初期化
      const workbook = new ExcelJS.Workbook(); // workbookを作成
      workbook.addWorksheet('名簿'); // worksheetを追加
      const worksheet = workbook.getWorksheet('名簿'); // 追加したworksheetを取得

      // ②データを用意
      // 各列のヘッダー
      worksheet.columns = [
        { header: 'ID', key: 'member_number' },
        { header: '名前', key: 'name' },
        { header: '種別', key: 'type' },
        { header: '郵便番号', key: 'zip_code' },
        { header: '住所', key: 'address' },
        { header: '電話番号', key: 'phone_number' },
        { header: '担当世話方', key: 'caretaker' },
        { header: '法味郵送', key: 'letter' },
        { header: '加入日', key: 'start_date' },
        { header: '連絡可能', key: 'contactable' },
      ];

      let events_data = JSON.parse(JSON.stringify(datatable));

      // true/falseを言葉に変換処理
      events_data.forEach((data) => {

        // excel csvがゼロ落ちする問題... 先頭にアポストロフィいれるべきか否か
        // 消えるのが目に見えているので一旦いれておく(日本人はエクセル好き)
        data.phone_number = "'" + data.phone_number  
       // data.phone_number = '="' + data.phone_number + '"'

        if(data.letter){
            data.letter = "送付"
        }else{
            data.letter = "未送"
        }
        if(data.contactable){
            data.contactable = "連絡可能"
        }else{
            data.contactable = "連絡不可"
        }
    });

      // 各行のデータ（worksheet.columnsのkeyがオブジェクトのキーと同じになる）
      worksheet.addRows(events_data); // memver_infoを入れる
      let charcode = this.charcode

      // ③ファイル生成
      const uint8Array =
        ext === 'xlsx'
          ? await workbook.xlsx.writeBuffer() // xlsxの場合
          : charcode === "UTF8"
          ? await workbook.csv.writeBuffer() // csvの場合
          : new Uint8Array(
            encoding.convert(await workbook.csv.writeBuffer(), {
              from: "UTF8",
              to: "SJIS"
            })
          );

      const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      const a = document.createElement('a');
      a.href = (window.URL || window.webkitURL).createObjectURL(blob);
      a.download = `檀家名簿.${ext}`;
      a.click();
      a.remove()
    
  },
    loadCsvFile() {
      let result = [];
    
      //let file = e.target.files[0];
      let file = this.upload_file

      var sjisArray = new Uint8Array(file );

      if (!file.type.match("text/csv")) {
      //  vm.message = "CSVファイルを選択してください";
        return;
      }

      let reader = new FileReader();
     // reader.readAsText(file, "SJIS");
     //reader.readAsText(file);

      let items = [];
      reader.onload = () => {

        // ファイル読み込み
        let fileResult =reader.result.split('\n');

        
        // 先頭行をヘッダとして格納
        let header = fileResult[0].split(',')
        
        // 先頭行の削除
        fileResult.shift();
        // 全てを格納する箱
        let linesArr = [];
        for (let i = 0; i < fileResult.length; i++) {
            // console.log(fileResult[i])
            linesArr[i] = fileResult[i].split(",");  
          
            };


        var box = [];
        //console.log(this.events)

        const mapValue = linesArr.map(value => {
            if(value[0]==""){
                return
            }else{
            var data_tmp = {};

            //　要素を多次元連想配列にいれていく
            // Validation処理必須！！！！！！！！！！！！！！！！！！！！今後追加！！！！
            
            data_tmp.member_id = value[0];  //門徒番号
            data_tmp.name = value[1];   //名前
            data_tmp.type = value[2];   //種別
            data_tmp.zip_code = value[3];   //郵便番号
            data_tmp.address = value[4];    //住所

            // データ整形
            let clean_phone_number = value[5].replace(/[\"]/g,"");   //電話番号
            data_tmp.phone_number = clean_phone_number.replace(/[\'']/g,""); 


            data_tmp.address = value[6];    //住所
           

            // 送付はブーリアン型なので変換（空白除去）
            if(value[7].trim() =="送付"){
                data_tmp.letter = true
            }else{
                data_tmp.letter = false
            }
            data_tmp.start_date = value[8];     //加入日

        
            // 送付はブーリアン型なので変換（空白除去）
            if(value[9].trim() =="連絡可能"){
                data_tmp.contactable = true;
            }else{
                data_tmp.contactable = false;
            }
            
            box.push(data_tmp);
            }
        
            return value;
});
       
        this.events = box

      }
      
    },
        async getFileContent () {
      try {
        const content = await this.readFileAsync(this.upload_file)  
       
        const cleaned_data = this.csvJSON(content)
        this.events = cleaned_data



      } catch (e) {
        console.log(e)
      }
    },
   
   readFileAsync (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          //文字列をバイト列に変換  
          var sjisArray = new Uint8Array(reader.result);
          //encode.jsを使い、文字コードを自動判別しUNICODEへ変換
          var unicodeArray = encoding.convert(sjisArray, {
            to: 'UNICODE',
            from: 'AUTO',
            type: 'string'
          });
          resolve(unicodeArray);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },
    
    confirmSave (event) {
            const Equality = this.isEqualArray()
            if(Equality == false){
                event.preventDefault();
                event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
            }
    },

csvJSON(csv){

  var lines=csv.split("\n");

  var result = [];

  var headers=lines[0].split(",");

  headers = ["member_number","name", "type", "zip_code","address", "phone_number" , "caretaker","letter", "start_date", "contactable" ]

  for(var i=1;i<lines.length;i++){

      var obj = {};
      
      var currentline=lines[i].split(",");

      for(var j=0;j<headers.length;j++){
          ///obj[headers[j]] = currentline[j];
          //obj = {"id":  currentline[j]}
          obj[headers[j]]= currentline[j]

      }
      
      result.push(obj);

  }
            
   // データ整形処理
  for (let count = 0; count < result.length; count++) {
            Object.keys(result[count]).forEach(function(key) {
         
            // console.log(key + '=' + result[count][key]);
            if(result[count][key] == null){ return
            }

            if(key == "phone_number"){
                let clean_phone_number = result[count].phone_number.replace(/[\"]/g,"");   //電話番号のダブルクォーテーションを消す
                result[count].phone_number = clean_phone_number.replace(/[\'']/g,""); //電話番号のアポストロフィ（ゼロ落ち防止）を消す
            }

            if(key == "letter"){
                if(result[count].letter.trim() == "送付"){ result[count].letter = true 
                }else{ result[count].letter = false
                }
            }
            if(key == "contactable"){
                if(result[count].contactable.trim() == "連絡可能"){result[count].contactable = true
                }else{result[count].contactable = false
                }
            }
            });
        }

  return result

},

readXlsxData(){
    
  let columnsFromHeader = true
  var result =[];
  const workbook = new ExcelJS.Workbook();
  
  workbook.xlsx.load(this.upload_file).then(function(){
  let headers = ["member_number","name", "type", "zip_code","address", "phone_number" , "caretaker", "letter", "start_date", "contactable" ]

    //最初のsheetを読み込んでいる
    const sheet = workbook.getWorksheet(1);
    //console.log(sheet)
    let columIndex= headers.length +1; // 読み込む列の数をここで設定する
    let rowIndex=1; //読み込み開始行
    if(columnsFromHeader){

      columIndex--;
      rowIndex++;
      
      while(sheet.getCell(rowIndex, 1).value !== null){
        var data ={};
        
        let col=1;
        while(col<=columIndex){
          data[headers[col-1]] =sheet.getCell(rowIndex, col++).value;
        }
        result.push(data);
        rowIndex++;
      }
    }else{

        return 
    }

     // データ整形処理
  for (let count = 0; count < result.length; count++) {
            Object.keys(result[count]).forEach(function(key) {
         
            // console.log(key + '=' + result[count][key]);
            if(result[count][key] == null){return}

            if(key == "phone_number"){
                let clean_phone_number = result[count].phone_number.replace(/[\"]/g,"");   //電話番号のダブルクォーテーションを消す
                result[count].phone_number = clean_phone_number.replace(/[\'']/g,""); //電話番号のアポストロフィ（ゼロ落ち防止）を消す
            }

            if(key == "letter"){
                if(result[count].letter.trim() == "送付"){
                    result[count].letter = true }else{result[count].letter = false}
            }

            if(key == "contactable"){
                if(result[count].contactable.trim() == "連絡可能"){ result[count].contactable = true
                }else{ result[count].contactable = false}
            }
            });
            }   
    return(result); 
  })
  return result
  // this.events = result // ここでデータを書き換える
  },


    async uploadController () {

        if(!this.upload_file){
            return 
        }

        let file_type = this.upload_file.name.split('.').pop();
        if(file_type == "csv"){
                  try {
                        const content = await this.readFileAsync(this.upload_file)  
                        const cleaned_data = this.csvJSON(content)
                        this.events = cleaned_data

                    } catch (e) {
                        console.log(e)
                    }


        }else if(file_type == "xlsx"){
                    try {
                        const content = await this.readXlsxData()
                        this.events = content
                    } catch (e) {
                        console.log(e)
                    }
        }


    },

    getHashMaxNumber(hash, keyName) {
	    var maxNumber;
      for (var i = 0, len = hash.length; i < len; i++) {
        if (!hash[i][keyName]) continue;
        if (typeof maxNumber === 'undefined' || hash[i][keyName] > maxNumber) {
          maxNumber = hash[i][keyName];}}
        return maxNumber;
      },


    new_member_add(){



        let new_id_num;
        if (this.events.length === 0) {
          new_id_num = 1
        }else{
          new_id_num = this.getHashMaxNumber(this.events, "member_number") + 1 //カレンダーIDの中で最大の値に1を加算させる
        }

        const formattedDate = this.new_member_start_date.replace(/-/g, "/");

        var new_events = {
                    member_id:"",
                    member_number: new_id_num,
                    name: this.new_member_name,
                    type: this.new_member_type,
                    zip_code: this.new_member_zip_code,
                    address:this.new_member_address,
                    letter: this.new_member_letter,
                    caretaker : this.new_member_caretaker,
                    phone_number: this.new_member_phone_number,
                    start_date: formattedDate ,
                    contactable: this.new_member_contactable
                }

        this.events.push(new_events);

    },
    
    getEvents() {

        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

    
        axios.get('https://www.tsukinami-system.com/fastapi/schedules/', config)
        .then(response => {
        // 成功時の処理etc
        let event_data = response.data
        let event_list = new Array();
        console.log(event_data)

        for(let i = 0; i < event_data.length; i++){
            let title = event_data[i].title
            // let opponent  = event_data[i].opponent
            // let responder = event_data[i].responder
            
            // //カレンダーに表示されるタイトルを作成
            // if(opponent){
            // title = title + "　" + opponent
            // }
            // if(responder){
            // title = title + "　(" + responder + ")"
            // }
            const timeOptions = { hour12: true };

            let event={
                id: event_data[i].id,
                event_id: event_data[i].event_id,
                name: title,
                unsaved: false,                
                event_name:event_data[i].title,
                opponent_member_id: event_data[i].opponent_member_id,
                event_opponent: event_data[i].opponent,
                event_dealer: event_data[i].responder,
                event_address:event_data[i].address,
                event_memo:event_data[i].memo,
                start_day: new Date(event_data[i].start_date).toLocaleDateString(),
                end_day: new Date(event_data[i].end_date).toLocaleDateString(),
                start_time: new Date(event_data[i].start_date).toLocaleTimeString([], {timeStyle: 'short'}),
                end_time: new Date(event_data[i].end_date).toLocaleTimeString([], {timeStyle: 'short'}),
                timed: event_data[i].timed,
                
        }
        event_list.push(event);
        }
        this.events = event_list
        //ここでデータ保持
        this.original_val = JSON.parse(JSON.stringify(this.events)); 

        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
    },

    clear () {
        this.$refs.input_form.resetValidation()
        this.new_member_name=""
        this.new_member_type=""
        this.new_member_zip_code=""
        this.new_member_address=""
        this.new_member_phone_number="",
        this.new_member_letter=false
        this.new_member_contactable=false
        this.new_member_start_date= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },


    toJapaneseCallable(bool){
        if(bool){return "連絡可能"}else{return "連絡不可"}
      },
    toJapaneseSendletter(bool){
        if(bool){return "送付"}else{return "未送"}
      },
}
}


