//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Inputs",
  },
  data: () => ({
    showMessages: false,
  }),

  computed: {
    messages () {
      return this.showMessages ? ['Message'] : undefined
    },
  },
}
