//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Faq Three'
    },
    data: () => ({
        item: 1,
        items: [
            {text: 'About Us', icon: 'mdi-google-my-business'},
            {text: 'License', icon: 'mdi-license'},
            {text: 'Multi Application', icon: 'mdi-devices'},
            {text: 'Terms of use', icon: 'mdi-rocket-launch'},
            {text: 'Reward policy', icon: 'mdi-library'},
            {text: 'mdi-security', icon: 'mdi-devices'}
        ]
    })
}
