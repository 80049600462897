//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Invoice Ver 2'
    },
    data() {
        return {
            desserts: [
                {
                    desc: 'UI/UX for Mobile App',
                    hrs: 50,
                    rate: 50.0,
                    amount: 2500
                },
                {
                    desc: 'Front-End Development',
                    hrs: 130,
                    rate: 50.0,
                    amount: 6500
                },
                {
                    desc: 'Back-End Development',
                    hrs: 130,
                    rate: 80.0,
                    amount: 10400
                }
            ]
        }
    }
}
