//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SignIn Three',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignIn Three'
    },
    data() {
        return {
            show: false,
            password: 'Password',
            selected: ['John']
        }
    }
}
