//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  spark1,
  spark2,
  spark3,
  spark4,
  spark5,
  spark6,
  spark7,
  spark8,
  spark9,
  spark10
} from "@/data/apexChart";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Apex- Sparkline Charts"
  },
  data() {
    return {
      spark1,
      spark2,
      spark3,
      spark4,
      spark5,
      spark6,
      spark7,
      spark8,
      spark9,
      spark10
    };
  },
  methods: {}
};
