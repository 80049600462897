//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";

export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
    SearchDrawer: () => import("../common-drawer/SearchDrawer.vue"),
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
      country: require("@/assets/images/flags/1x1/us.svg"),
      flags: [
        {
          country: require("@/assets/images/flags/1x1/us.svg"),
          lang: "English",
        },
        {
          country: require("@/assets/images/flags/1x1/es.svg"),
          lang: "Spanish",
        },
        {
          country: require("@/assets/images/flags/1x1/in.svg"),
          lang: "India",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      ,
      "changeVerticalSidebarMini",
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
  },
};
