//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TestCard',

    props: {
        // Floating Button icon Color
        floatingButtonIconTextColor: {
            type: String,
            default: 'white--text'
        },

        // Floating Button Background Color
        floatingButtonBackgroundColor: {
            type: String,
            default: ''
        },

        // Floating Button Icon Name
        floatingButtonIconName: {
            type: String,
            default: 'mdi-plus'
        },
        // card  main icon color
        mainIconTextColor: {
            type: String,
            default: 'primary--text'
        },
        // card  main icon background color
        mainIconBackgroundColorClass: {
            type: String,
            default: 'primary'
        },
        // card  main icon name
        mainIconName: {
            type: String,
            default: 'mdi-account-multiple-outline'
        },

        //  Heading Text
        headingText: {
            type: String,
            default: '30.2k'
        },

        // card Sub Heading Text
        subHeadingText: {
            type: String,
            default: 'Total Followers'
        },

        // bottom button text
        bottomButtonText: {
            type: String,
            default: '12.6%'
        },
        // bottom button icon
        bottomButtonIcon: {
            type: String,
            default: 'mdi-arrow-down-drop-circle-outline'
        },
        // bottom button hover class
        bottomButtonHoverClass: {
            type: String,
            default: ''
        }
    }
}
