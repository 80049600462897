//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';

export default {

    mounted() {
        //データ取得API関数実行
        this.getEvents()
    },
   
    
    data: () => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      title: "", 
      opponent: "",
      dealer: "",
      content:"",
      memo:"",
      address:"",
      opponent_member_id:"",
      select: null,
      items: [
        '会計費',
        'お寺費用',
        '交通費',
        'その他諸々',
      ],
      checkbox: false,
      errorMessages:"",
      menu:"",
      // 入力規則
      required: value => !!value || "必ず入力してください", // 入力必須の制約
    }),
    methods: {
      submit () {
        this.$refs.input_form.validate()
          if (this.$refs.test_form.validate()) {
          // すべてのバリデーションが通過したときのみ
          // if文の中に入る
          const formattedDate = this.date.replace(/-/g, "/");
          
          let newReport = { 
                          date: formattedDate,
                          title: this.title,
                          dealer: this.dealer,
                          opponent:this.opponent,
                          opponent_member_id: this.opponent_member_id, 
                          content: this.content,
                          address: this.address,
                          member_id:this.member_id,
                          memo: this.note}
        
          let user_info = localStorage.getItem('userInfo')
          user_info = JSON.parse(user_info)
          let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
              
          //DB編集APIを投げる
          axios.post('https://www.tsukinami-system.com/fastapi/add_report', newReport, config)
                  .then((response) => {
                    console.log(response);
                  }, (error) => {
                    console.log(error);
                  });  
          } else {
          
          }
  
      },
      clear () {
        this.$refs.input_form.resetValidation()
        this.title = ''
        this.content = ""
        this.memo = ""
      },
      getParam(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      },
      getEvents() {

        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

        let id = this.getParam('id')
        axios.get('https://www.tsukinami-system.com/fastapi/one_schedule?id=' + id, config)
        .then(response => {
          //console.log(response.data)
          
          // 成功時の処理etc
          let event_data = response.data
        
          this.date = new Date(event_data.start_date).toLocaleDateString() //開始日
          this.title = event_data.title
          this.opponent = event_data.opponent
          this.opponent_member_id = event_data.opponent_member_id,
          this.dealer =  event_data.responder
          this.address = event_data.address


        })
        .catch(error => {
          // 失敗時の処理etc
          alert(error)
          return error
        })
        },
    },
  }
