//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Paginations",
  },
  data () {
    return {
      page: 1,
    }
  },
}
