//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'VerticallAppBar',

    computed: {
        ...mapGetters(['getThemeMode'])
    },
    data() {
        return {
            userDrawer: false,
            notificationDrawer: false,
            searchDrawer: false
        }
    },
    methods: {
        ...mapActions(['changeCompactVerticalSidebarDrawer']),
        toggleCompactVerticalSidebarDrawer() {
            this.changeCompactVerticalSidebarDrawer()
        }
    }
}
