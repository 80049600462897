//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapChart from 'vue-map-chart'
export default {
   components: {
      MapChart,
   },
   data(){
      return {

      }
   }
}
