//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    name: "", 
    client: "",
    worker: "",
    zip:"",
    address:"",
    select: null,
    items: [
      '会計費',
      'お寺費用',
      '交通費',
      'その他諸々',
    ],
    checkbox: false,
    errorMessages:"",
    menu:"",
    // 入力規則
    required: value => !!value || "必ず入力してください", // 入力必須の制約
  }),

  methods: {
    submit () {
      this.$refs.input_form.validate()
        if (this.$refs.test_form.validate()) {
        
          const formattedDate = this.date.replace(/-/g, "/");
        
          let newReport = { 
                          date: formattedDate,
                          title: this.name_content,
                          dealer: this.worker,
                          content: this.content,
                          address: this.address,
                          member_id:this.member_id,
                          memo: this.note}
        
          let user_info = localStorage.getItem('userInfo')
          user_info = JSON.parse(user_info)
          let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
              
          //DB編集APIを投げる
          axios.post('https://www.tsukinami-system.com/fastapi/add_report', newReport, config)
                  .then((response) => {
                    console.log(response);
                  }, (error) => {
                    console.log(error);
                  });  
          
        
        } else {
        
        }
    },
    clear () {
      
      this.$refs.input_form.resetValidation()
      this.name = ''
      this.address =  ''
      this.zip =  ''
      this.client =  ''
      this.worker =  '' 
      this.select = null
      this.checkbox = false
    },
  },
}
