//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        bgCardColor: {
            type: String,
            default: ''
        },
        mainIconTextColor: {
            type: String,
            default: ''
        },
        mainIconName: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        subHeading: {
            type: String,
            default: ''
        }
    }
}
