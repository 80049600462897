//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import axios from 'axios';

export default {
    name: 'signup',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignUp 2'
    },
    data() {
        return {
            show: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',
            ePassword: '',
            loading: false,
            snackbar: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            nameRules: [
                (v) => !!v || 'Password is required',
                (v) =>
                    (v && v.length >= 6) ||
                    'Name must be greater than 6 characters'
            ]
        }
    },

    methods: {
        ...mapActions(['signUserUp']),
        submit() {
            // axiosでサーバに情報送信
            if (this.email && this.ePassword) {
                this.signUserUp({email: this.email, password: this.ePassword})
            }

        },
         
    }
}

