//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard'
import {
    simplePie,
    simpleDonut,
    monochromePie,
    gradientDonut,
    donutwithPattern
} from '@/data/apexChart.js'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Apex- Pie/Donut Charts'
    },
    components: {
        ChartCard
    },
    data() {
        return {
            simplePie,
            simpleDonut,
            monochromePie,
            gradientDonut,
            donutwithPattern
        }
    }
}
