//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import axios from 'axios';
import { format } from 'date-fns/fp';
export default {
  data: () => ({
    dialog:false,
    EditModeActive: false,
    original_val:[], // 初期値を入れとく変数
    dragEvent: null,
    createEvent: null,
    createStart: null,
    
    value: moment().format('yyyy-MM-DD'),
    type: "month",
      types: [{id:"month", text: '月'},{id:"week", text: '週'},{id: "day", text: '日'}, {id:"4day", text: '4日'}],
      mode: "stack",
      modes: [{id:"stack", text: '可'},{id:"column", text: '不可'}],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "日曜日 - 土曜日", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "月曜日 - 日曜日", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "月曜日 - 金曜日", value: [1, 2, 3, 4, 5] },
        { text: "金曜日, 土曜日, 日曜日", value: [5, 6, 0] }
      ],
    menu1:"",
    menu2:"",
    // 入力規則
    required: value => !!value || "必ず入力してください", // 入力必須の制約
    saved_event_id: 0,
    event_unsaved: true,
    event_id: 0,
    event_start_day: "2000-1-01",
    event_end_day: "",
    event_start_time: null,
    event_end_time: null,
    event_name : null,
    event_opponent : null,
    event_opponent_member_id : null,
    event_dealer : null,
    event_address : null,
    event_memo : null,
    switch_start_fullday: false,
    switch_end_fullday: false,
    workers_color:[],
    worker_names:[],
    member_info:[],
    events:[]

  }),

    created () {
        // 相手先を取得
        this.getWorkers()
        this.getEvents()
        this.getMembers()

  },

    destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },

    beforeRouteLeave (to, from , next) {
        
            const Equality = this.isEqualArray()
            if(Equality == false){
               
                const answer = window.confirm('新たに編集された予定は保存されていません。未保存のままスケジュール画面を閉じてもよろしいでしょうか？')

                if (answer) { next() }else{ next(false) }
            }else{next()}
},

   watch: {

    event_start_day: function(newVal, oldVal) {
      // このコードは開始日にて終日が選択されているのに終了日で時間が指定されている時かつ同日であるときに発動(終日の矛盾を防ぐ)
      if(this.event_start_day == this.event_end_day && this.switch_start_fullday != this.switch_end_fullday){
        this.switch_end_fullday= this.switch_start_fullday}
      // 開始日
      if(this.event_start_day > this.event_end_day){
      this.event_end_day = String(this.event_start_day)}
      if(this.event_start_day == this.event_end_day &&  this.event_end_time < this.event_start_time){
      this.event_end_time= String(this.event_start_time)}},

    event_end_day: function(newVal, oldVal) {
      // このコードは開始日にて終日が選択されているのに終了日で時間が指定されている時かつ同日であるときに発動(終日の矛盾を防ぐ)
      if(this.event_start_day == this.event_end_day && this.switch_start_fullday != this.switch_end_fullday){
        this.switch_end_fullday= this.switch_start_fullday}
      // 終了日
      if(this.event_start_day > this.event_end_day){
      this.event_start_day = String(this.event_end_day)}
      if(this.event_start_day == this.event_end_day &&  this.event_end_time < this.event_start_time){
      this.event_start_time= String(this.event_end_time)}},  
    
    event_start_time: function(newVal, oldVal) {
      // 開始時間
      if(this.event_start_day == this.event_end_day &&  this.event_end_time < this.event_start_time){
      this.event_end_time= String(this.event_start_time)}},

    event_end_time: function(newVal, oldVal) {
      // 開始時間
      if(this.event_start_day == this.event_end_day &&  this.event_end_time < this.event_start_time){
      this.event_start_time = String(this.event_end_time)}},

    switch_start_fullday: function(newVal, oldVal) {
      // このコードは開始日にて終日が選択されているのに終了日で時間が指定されている時かつ同日であるときに発動
      if(this.event_start_day == this.event_end_day && this.switch_start_fullday != this.switch_end_fullday){
        this.switch_end_fullday= this.switch_start_fullday}},

    switch_end_fullday: function(newVal, oldVal) {
      // このコードは開始日にて終日が選択されているのに終了日で時間が指定されている時かつ同日であるときに発動
      if(this.event_start_day == this.event_end_day && this.switch_end_fullday != this.switch_start_fullday){
        this.switch_start_fullday= this.switch_end_fullday}},
    }, 

  mounted() {
        //member_infoの初期値入れとく処理
        this.original_val = JSON.parse(JSON.stringify(this.events));
      
      },

  computed: {

    title() {
      return moment(this.value).format('yyyy年M月');
    },
  },
  
  methods: {

    checkMemberID(){

        // member_IDを検索する 門徒かどうかチェック カレンダーと門徒を紐づけ処理
        const isFound = this.member_names.includes(this.event_opponent);
  
        if(isFound  == true){
        let str = this.event_opponent;
        let [opponent_member_id, member_name] = str.split('　');
        this.event_opponent_member_id =opponent_member_id

        let member_info = this.member_info.find( ({ user_id }) => user_id == opponent_member_id );

        this.event_address = member_info.user_address

        }else{
        this.event_opponent_member_id = ""
        }
    },

    color_selection(color){
      let color_code;
      if (color === '赤') {
        color_code = "red"
        } else if (color === '青') {
          color_code  = "blue"
        } else if (color === '黄') {
          color_code  = "yellow darken-2"
        } else if (color === '緑') {
          color_code  = "green"
        } else if (color === '紫') {
          color_code  = "purple"
        } else if (color === '橙') {
          color_code = "orange"
        } else if (color === '茶') {
          color_code  = "brown"
        } else if (color === '朱') {
          color_code = "deep-orange darken-2"
        } else if (color === '黒') {
          color_code  = "black"
        } else if (color === '濃青') {
          color_code  = "indigo darken-4"
        } else if (color === '濃緑') {
          color_code  = "green darken-4"
        } else if (color === '濃紫') {
          color_code = "deep-purple darken-2"
        } else {
          color_code = "grey"   
        }

        return color_code

      },
  
    send_save(){
      
      let indexNum = this.events.findIndex((e) => e.event_id == [this.event_id]);
     
      indexNum = Number(indexNum) 

      // events多次元連想配列内のidを検索ある場合のみ更新    
      if (indexNum != -1 ) {

      const formatTime = format('HH:mm:ss');
      //startDateオブジェクトに代入
      var event_start_Date = String(this.event_start_day)  + "T" + String(this.event_start_time) ;
      event_start_Date = new Date(event_start_Date);
      this.events[indexNum]["start"] =new Date(event_start_Date) 
      
        //  if(this.switch_start_fullday){
        //    this.events[indexNum]["start_day_timed"] = false
        //    this.events[indexNum]["timed"] = false
        //  }else{
      //      this.events[indexNum]["start_day_timed"] = true
      //     this.events[indexNum]["timed"] = true
      //   }
      //   if(this.switch_end_fullday){
      //     this.events[indexNum]["end_day_timed"] = false
      //     this.events[indexNum]["timed"] = false
      //   }else{
      //     this.events[indexNum]["end_day_timed"] = true
    //this.events[indexNum]["timed"] = true
      //   }

      // member_IDを検索する 門徒かどうかチェック カレンダーと門徒を紐づけ処理
      const isFound = this.member_names.includes(this.event_opponent);
      console.log(this.event_opponent)
      if(isFound  == true){
      let str = this.event_opponent;
      let [opponent_member_id, member_name] = str.split('　');
      this.event_opponent = member_name
      this.events[indexNum]["opponent_member_id"] =opponent_member_id
      }else{
      this.events[indexNum]["opponent_member_id"] = ""
      }

      if(this.switch_start_fullday == true && this.switch_end_fullday == true){
        this.events[indexNum]["start_day_timed"] = false
        this.events[indexNum]["end_day_timed"] = false
        this.events[indexNum]["timed"] = false
      }else if(this.switch_start_fullday == true && this.switch_end_fullday == false){
        this.events[indexNum]["start_day_timed"] = false
        this.events[indexNum]["end_day_timed"] = true
        this.events[indexNum]["timed"] = false
      }else if(this.switch_start_fullday == false && this.switch_end_fullday == true){
        this.events[indexNum]["start_day_timed"] = true
        this.events[indexNum]["end_day_timed"] = false
        this.events[indexNum]["timed"] = false
      }else{
        this.events[indexNum]["start_day_timed"] = true
        this.events[indexNum]["end_day_timed"] = true
        this.events[indexNum]["timed"] = true
      }

      //endtDateオブジェクトに代入
      var event_end_Date = String(this.event_end_day)  + "T" + String(this.event_end_time) ;
      event_end_Date = new Date(event_end_Date);
      this.events[indexNum]["end"] =new Date(event_end_Date) 

      // データが存在した時の処理
      this.events[indexNum]["event_name"] = this.event_name // this.event_name
      this.events[indexNum]["event_opponent"] = this.event_opponent
      this.events[indexNum]["event_dealer"]= this.event_dealer
      this.events[indexNum]["event_address"] = this.event_address
      this.events[indexNum]["event_memo"] = this.event_memo
       
      var opponent = ""
      var dealer = ""
      // カレンダーの表示用　予定名　相手先　（対応者）の順で表示する
      if(this.event_opponent){
        opponent = "　" + this.event_opponent 
      }
      if(this.event_dealer){
        dealer = "　(" + this.event_dealer + ")"

        //寺務員のカレンダーの色を反映する 
        // let worker_info = this.workers.find(item => item.name=== this.event_dealer)
        // if(worker_info){
        // this.events[indexNum]["color"] = worker_info.color
        // }

        // member_IDを検索する 門徒かどうかチェック カレンダーと門徒を紐づけ処理
        const isFound = this.worker_names.includes(this.event_dealer);
        if(isFound  == true){
          let name = this.event_dealer;
          let dic = this.worker_color;
          let color;

          for (let i = 0; i < dic.length; i++) {
            if (dic[i].name === name) {
              color = dic[i].color;
              break;
            }}
            this.events[indexNum]["color"] = this.color_selection(color)

          }else{
              this.events[indexNum]["color"] = "green"
          }
    }

      this.events[indexNum]["name"] = this.event_name + opponent + dealer
      
        } else {
        // データが存在しなかった時の処理
        console.log("存在しない予定データです")
          //this.events[0]["name"] = this.event_name
          //console.log(this.events[1])
        }
   
    },

  setToday() {
      this.value = moment().format('yyyy-MM-DD');
    },
    showEvent({ event }) {

      this.event_unsaved = event.unsaved //保存前はボタンおせないようにする
      if (event.id  !== null) {
        //正式なイベントidをここにほりこんでおく
        //報告書で参照用
      this.saved_event_id = event.id 
      }else{
      this.saved_event_id = ""
      }
      // トグルの表示切り替え
      if(event.start_day_timed){
      this.switch_start_fullday = false
      }else{
        this.switch_start_fullday = true
      }
      if(event.end_day_timed){
      this.switch_end_fullday = false
      }else{
        this.switch_end_fullday = true
      }
      
      //format用の関数を準備
      const formatDay = format('yyyy-MM-dd');
      //開始日データ
      const start_day = event.start
      const end_day = event.end

      //文字列化
      const start_dayString = formatDay(start_day);
      const end_dayString = formatDay(end_day);
      this.event_start_day = start_dayString
      this.event_end_day = end_dayString

      const formatTime = format('HH:mm:ss');
      //開始日データ
      const start_time = event.start
      const end_time = event.end

      //文字列化
      const start_timeString = formatTime(start_time);
      const end_timeString = formatTime(end_time);
      this.event_start_time = start_timeString
      this.event_end_time = end_timeString

      this.event_name = event.event_name
      this.event_dealer = event.event_dealer
      this.event_address = event.event_address
      this.event_memo = event.event_memo
      this.event_id = event.event_id //イベントIDを変数化

      //もしmember_idがあるなら、先頭につけてあげる
      if (event.opponent_member_id === "") {
        this.event_opponent = event.event_opponent
        } else {
        this.event_opponent =  event.opponent_member_id + "　" + event.event_opponent
        this.event_opponent_member_id = event.opponent_member_id
        }
      this.dialog = true
    },
    jumptoProfile(){
        
      //報告書画面に遷移
      window.open('https://www.tsukinami-system.com/app/profile/profile-one?id='+ this.event_opponent_member_id  , '_blank'); // 新しいタブを開き、ページを表示　
      
    },
    jumptoReport(){
      let event_id = this.saved_event_id //DBに保存されているイベント
      //報告書画面に遷移
      window.open('https://www.tsukinami-system.com/app/forms/workform'+ '?id='+ event_id, '_blank'); // 新しいタブを開き、ページを表示

    },
    viewDay({ date }) {
      this.type = "day"

    },
    jumptoMap(){

      const language = 'ja'; // 日本語の言語コード
      // GoogleMapに遷移
      window.open('https://www.google.com/maps/search/?api=1&hl=ja&gl=ja&query=' + this.event_address, '_blank');
      // window.open('https://www.google.com/maps/search/?api=1&query=' + this.event_address, '_blank');


    },
    getEvents() {

    let user_info = localStorage.getItem('userInfo')
    user_info = JSON.parse(user_info)
    let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

    
    axios.get('https://www.tsukinami-system.com/fastapi/schedules/', config)
    .then(response => {
      
      // 成功時の処理etc
      let event_data = response.data
      let event_list = new Array();

      for(let i = 0; i < event_data.length; i++){

        let title = event_data[i].title
        let opponent  = event_data[i].opponent
        let responder = event_data[i].responder
        
        //カレンダーに表示されるタイトルを作成
        if(opponent){
          title = title + "　" + opponent
        }
        if(responder){
          title = title + "　(" + responder + ")"
        }

        let color_code
        // member_IDを検索する 門徒かどうかチェック カレンダーと門徒を紐づけ処理
        const isFound = this.worker_names.includes(responder );
        if(isFound  == true){
    
          let dic = this.worker_color;
          let color;

          for (let i = 0; i < dic.length; i++) {
            if (dic[i].name === responder) {
              color = dic[i].color;
              break;
            }}

            color_code = this.color_selection(color)

        }else{
            color_code = "green"
        }

        let event={
        id: event_data[i].id,
        event_id: event_data[i].event_id,
        name: title,
        unsaved: false,                
        event_name:event_data[i].title,
        opponent_member_id: event_data[i].opponent_member_id,
        event_opponent: event_data[i].opponent,
        event_dealer: event_data[i].responder,
        event_address:event_data[i].address,
        event_memo:event_data[i].memo,
        start: new Date(event_data[i].start_date),
        end: new Date(event_data[i].end_date),
        color: color_code,
        timed: event_data[i].timed,
        start_day_timed: event_data[i].start_day_timed,
        end_day_timed: event_data[i].end_day_timed
      }
       event_list.push(event)
    
      }
      this.events = event_list 
      this.original_val = JSON.parse(JSON.stringify(this.events));     
      return response
    })
    .catch(error => {
      // 失敗時の処理etc
      alert(error)
      return error
    })
    },


    getEventColor(event) {
      return event.color;
    },

    startTime (tms) {

       if(!this.EditModeActive){
        // 編集モードがON担っていない場合はすぐreturn
        return
       }
      
       const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          return 
        } else {
          this.createStart = this.roundTime(mouse)
          let colors= ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575']
          //console.log(this.events )
          let new_id_num;
          if (this.events.length === 0) {
          new_id_num = 1
          }else{
          new_id_num = this.getHashMaxNumber(this.events, "event_id") + 1 //カレンダーIDの中で最大の値に1を加算させる
          }

          //console.log(new_id_num)

          this.createEvent = {
       
              event_id: new_id_num, //新規追加の場合でもデータ紐づけ用にIDは振る
              unsaved: true, //新規追加フラグ
              name: '新しい予定',
              event_name:'新しい予定',
              opponent_member_id: '',
              event_opponent: '',
              event_dealer: '',
              event_address:'',
              event_memo:'',
              start:  this.createStart,
              end: this.createStart,
              color: '#3F51B5',
              timed: true,
              start_day_timed: true,
              end_day_timed: true
          }

          this.events.push(this.createEvent)
        }
        
      },
        toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute ).getTime()
      },
      
      roundTime (time, down = true) {
        const roundTo = 10 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },

        rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      startDrag ({ event, timed }) {
        //必須処理
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          
        }
      },

      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      endDrag () {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
      }, 
      
      active: function () {
        let Equality = this.isEqualArray()

        // 編集完了し、編集内容を保存するか確認 
        if(this.EditModeActive == true && Equality == false){

            const answer1 = window.confirm("編集内容を保存してもよろしいでしょうか？")
            if(answer1){
                const scheduleData = this.events
                //console.log(scheduleData)

                let user_info = localStorage.getItem('userInfo')
                user_info = JSON.parse(user_info)
                let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
                
                //DB編集APIを投げる
                //axios.post('https://www.tsukinami-system.com/fastapi/schedules', scheduleData)
                axios.post('https://www.tsukinami-system.com/fastapi/schedules', scheduleData, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });

            
                //member_infoの初期値を更新する
                this.original_val = JSON.parse(JSON.stringify(this.events));
                
                // ボタンを変える
                this.EditModeActive = !this.EditModeActive;
            }else{ 
                const answer2 = window.confirm("編集内容を破棄して編集モードを終了しますか？")
                 if(answer2){
                    //編集内容を初期値に戻す
                    this.events = JSON.parse(JSON.stringify(this.original_val)); 
                    // ボタンを変える 
                    this.EditModeActive = !this.EditModeActive;
                 }else{
                    //何もせずに戻る
                    return
                 }
        }
        }else{
        // ボタンを変える 
        this.EditModeActive = !this.EditModeActive;
        }
       },


    isEqualArray () {
      // 連想配列の中身が一致しているか確認するメソッド
        let old_array = this.original_val
        let new_array = JSON.parse(JSON.stringify(this.events)); 

        if(new_array.length ==  old_array.length){
            //数が一致しているかをまず確認

            for(let i = 0; i < new_array.length; i++){
                
                let new_line = new_array.slice(i, i+1)
                let old_line = old_array.slice(i, i+1)

                new_line = new_line[0]
                old_line = old_line[0]

                for (let key in new_line) {
                    //console.log('key:' + key + ' value:' + new_line[key]);
                    //console.log('key:' + key + ' value:' + old_line[key]);

                    if(new_line[key] != old_line[key]){
                        // 中身が異なる
                        return false

                    }else{
                        continue;
                    }
                    }
        } return true
            
        }else{
            // 連想配列の数が異なる
            return false
             }
    },

        confirmSave (event) {
            const Equality = this.isEqualArray()
            if(Equality == false){
                event.preventDefault();
                event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
            }
    },

    getHashMaxNumber(hash, keyName) {
	    var maxNumber;
      for (var i = 0, len = hash.length; i < len; i++) {
        if (!hash[i][keyName]) continue;
        if (typeof maxNumber === 'undefined' || hash[i][keyName] > maxNumber) {
          maxNumber = hash[i][keyName];}}
        return maxNumber;
      },

      remove () {

      let indexNum = this.events.findIndex((e) => e.event_id == [this.event_id]);
      indexNum = Number(indexNum) 

      // console.log(worker_info.member_id)
      const index = this.events.indexOf(indexNum)
      const answer = window.confirm("この予定を削除してもよろしいでしょうか？")
      if(answer){
        this.events.splice(indexNum , 1)
      }else{ return }
      },  
    
      getWorkers() {
      let user_info = localStorage.getItem('userInfo')
      user_info = JSON.parse(user_info)
      let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
      

      axios.get('https://www.tsukinami-system.com/fastapi/workers', config)
      .then(response => {

      // 成功時の処理etc
      let event_data = response.data
      let event_list1 = new Array();
      let event_list2 = new Array();

      for(let i = 0; i < event_data.length; i++){
          console.log(i)

          let data1 = String(event_data[i].name)
          event_list1.push(data1);

          let data2 = {name: String(event_data[i].name),
                      color: String(event_data[i].color)}
          event_list2.push(data2);
        
        }
          this.worker_names = event_list1
          this.worker_color = event_list2

      return response
      })
      .catch(error => {
      // 失敗時の処理etc
      alert(error)
      return error
      })
      },    

    getMembers() {
      let user_info = localStorage.getItem('userInfo')
      user_info = JSON.parse(user_info)
      let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
      
      axios.get('https://www.tsukinami-system.com/fastapi/members', config)
      .then(response => {
      
      // 成功時の処理etc
      let event_data = response.data
      let event_list = new Array();

      var member_info = [];

      for(let i = 0; i < event_data.length; i++){         
          let name = String(event_data[i].id) +"　" + String(event_data[i].name)

          // 配列内の最後に要素を追加
          member_info.push({user_id: event_data[i].id, user_name: event_data[i].name, user_address: event_data[i].address});

      event_list.push(name);
      }
      this.member_names = event_list

      this.member_info = member_info

      return response
      })
      .catch(error => {
      // 失敗時の処理etc
      alert(error)
      return error
      })
      },    

 
    
  },
};
