//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// In your Vue.js component.
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Product Details'
    },
    components: {VueperSlides, VueperSlide},

    data: () => ({
        tab: null,
        productRating: 4,
        rating: 4,
        items: [
            {
                tab: 'Descriptions',
                content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra nibh et urna tempus, id feugiat sapien iaculis. Morbi aliquam et nisl ut varius. Vivamus fringilla, nisl ac lacinia fermentum, nisl felis facilisis lectus, id condimentum turpis quam ac ipsum. Vivamus purus nisi, condimentum eget felis vel, finibus interdum odio. Pellentesque lacinia aliquam ipsum. Donec euismod ex in sodales faucibus. Proin eget lacus vitae eros vestibulum vehicula. Mauris tortor massa, fringilla ut metus quis, malesuada semper diam. Phasellus et iaculis ex, vitae maximus odio.'
            },
            {
                tab: 'Specifications',
                content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra nibh et urna tempus, id feugiat sapien iaculis. Morbi aliquam et nisl ut varius. Vivamus fringilla, nisl ac lacinia fermentum, nisl felis facilisis lectus, id condimentum turpis quam ac ipsum. Vivamus purus nisi, condimentum eget felis vel, finibus interdum odio. Pellentesque lacinia aliquam ipsum. Donec euismod ex in sodales faucibus. Proin eget lacus vitae eros vestibulum vehicula. Mauris tortor massa, fringilla ut metus quis, malesuada semper diam. Phasellus et iaculis ex, vitae maximus odio.'
            },
            {
                tab: 'Review',
                content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra nibh et urna tempus, id feugiat sapien iaculis. Morbi aliquam et nisl ut varius. Vivamus fringilla, nisl ac lacinia fermentum, nisl felis facilisis lectus, id condimentum turpis quam ac ipsum. Vivamus purus nisi, condimentum eget felis vel, finibus interdum odio. Pellentesque lacinia aliquam ipsum. Donec euismod ex in sodales faucibus. Proin eget lacus vitae eros vestibulum vehicula. Mauris tortor massa, fringilla ut metus quis, malesuada semper diam. Phasellus et iaculis ex, vitae maximus odio.'
            }
        ],
        cards: [
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            }
        ],
        slides: [
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/iphone-1.jpg')},
            {image: require('@/assets/images/products/speaker-1.jpg')},
            {image: require('@/assets/images/products/watch-2.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')}
        ]
    })
}
