//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SideBar: () => import("./Sidebar"),
    BaseView: () => import("./View"),
    Header: () => import("./Header.vue"),
  },
  computed: {
    ...mapGetters(["getThemeMode"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
