//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Edit Invoice",
  },
  data() {
    return {
      modal: false,
      date: new Date().toISOString().substr(0, 10),
      radios: "radio-1",

      newRow: "",
      rows: [0],
    };
  },
  methods: {
    addItem: function () {
      this.rows.push(this.newRow);
    },
    deleteItem: function (deleteItem) {
      this.rows.splice(deleteItem, 1);
    },
  },
};
