//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
export default {
    name: 'Note',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Note'
    },
    components: {
        VuePerfectScrollbar
    },
    created() {
        // axios.get('/api/notes').then((response) => {
        //     this.notes = response.data
        //})
        // .catch(err => console.log(err))
    },
    data: () => ({
        dialog: false,
        isOpen: false,
        isBlock: false,
        show_flag:"all",
        notes: [ {
            id: 1,
            title: '大掃除',
            text:'本堂と境内の大掃除',
            importance: true,
            check: false,
            color: 'info lighten-4'
        },
        {
            id: 2,
            title: '市のボランティア',
            text:'市のボランティアに参加',
            importance: true,
            check: false,
            color: 'success lighten-4'
        },
        {
            id: 3,
            title: '墓地の改修',
            text:'業者とのやり取り',
            importance: false,
            check: false,
            color: 'warning lighten-4'
        }

        ],
        newNote: {},
        title: '',
        text: '',
        color: '',
        // item: 1,
        // itemsTwo: 0,
        drawer: null,
        drawerArray: [
            {title: 'Home', icon: 'dashboard'},
            {title: 'About', icon: 'question_answer'}
        ],
        items: [
            {text: '全て', icon: 'mdi-clipboard-list-outline'},
            {text: '重要', icon: 'mdi-star'},
            {text: '未完了', icon: 'mdi-check-outline'},
            {text: '完了', icon: 'mdi-check-bold'},
           

        ]
    }),
    methods: {
    
    flag_check(note) { 
    if(this.show_flag === "all"){
         return true
    }else if(this.show_flag === "importance"){
        if(note.importance){
            return true
        }else{
            return false
        }    
    }else if(this.show_flag ==="imcomplete"){
        if(note.check){
            return false
        }else{
            return true
        }
    }else if(this.show_flag ==="complete"){
        if(note.check){
            return true
        }else{
            return false
        }
    }

    },        
    clickcheck(note){
        if(note.check){note.check = false}else{note.check = true}
    },
    clickimportance(note){
        if(note.importance){note.importance = false}else{note.importance = true}
    },
    clickitem(item){
        
        if(item.text === "全て"){
            this.show_flag= "all"
        }else if(item.text === "重要"){ 
            this.show_flag= "importance"
        }else if(item.text === "未完了"){
            this.show_flag= "imcomplete"
        }else if(item.text === "完了"){
            this.show_flag= "complete"
        }


    },
        // addNote: function() {
        //   console.log(this.title,this.color)
        //   axios.post('/api/notes', { data: this.text, dataTwo: this.title, color: this.color})
        //     .then(response => {
        //       console.log(response.data.title);
        //       this.notes.push({
        //         cardColor: this.color,
        //         trashColor: this.color,
        //         settingsColor: this.color,
        //         dividerColor: this.color,
        //         title: response.data.title,
        //         date: '11/05/2020',
        //         text: response.data.text
        //       })
        //     })
        //   this.title = ''
        //   this.text = ''
        //   this.dialog = false
        // },
        // deleteNote: function(id){
        //   // console.log(id)
        //   axios.delete(`/api/notes/${id}`, { data: this.text, dataTwo: this.title})
        //     .then(response => {
        //       // this.notes = this.notes.filter(note => id !== notes.id)
        //       let notes = this.notes.filter(note => note.id !== id)

        //       this.notes = notes
        //     })
        // },

        saveNote: function() {
            axios
                .post('/api/notes/add', {data: this.newNote})
                .then((response) => {
                    this.notes.push({
                        title: this.newNote.title,
                        text: this.newNote.text,
                        color: this.newNote.color.hex
                    })
                })
            this.dialog = false
        },

        deleteNote: function(note) {
            axios.post('/api/notes/delete', note).then((response) => {
                this.notes = this.notes.filter((x) => x.id !== note.id)
            })
        },

        appToggle: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        overlayApp: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        remove () {

        let indexNum = this.events.findIndex((e) => e.event_id == [this.event_id]);
        indexNum = Number(indexNum) 
        console.log(indexNum)

        // console.log(worker_info.member_id)
        const index = this.events.indexOf(indexNum)
        const answer = window.confirm("この予定を削除してもよろしいでしょうか？")
        if(answer){
        this.events.splice(indexNum , 1)
        }else{ return }
},  
    }
}
