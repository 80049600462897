//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile 2'
    },
    data() {
        return {
            slides: [
                {
                    img: require('@/assets/images/vue.png'),
                    title: 'Take Course On Vue Today',
                    paragraph:
                        'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
                },
                {
                    img: require('@/assets/images/Sass_icon.png'),
                    title: 'Communicate With Your Customers',
                    paragraph:
                        'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
                },
                {
                    img: require('@/assets/images/Html_Icon.png'),
                    title: 'Take Course On HTML Today',
                    paragraph:
                        'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
                }
            ]
        }
    }
}
