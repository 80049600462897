//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EInfoCard from '@/components/card/BasicInfoCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account Home'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            home: [
                {
                    title: 'Privacy & personalization',
                    subTitle:
                        'See the data in your UI Lib Account and choose what activity is saved to personalize your UI Lib experience',
                    img: require('@/assets/images/svg/artist.svg'),
                    link: 'Manage your data & personalization'
                },
                {
                    title: 'Critical security issues found',
                    subTitle:
                        'Protect your account now by resolving the issues',
                    img: require('@/assets/images/svg/data-security.svg'),
                    link: 'Take action'
                },
                {
                    title: 'Account storage',
                    subTitle:
                        'Your account storage is shared across UI Lib services, like UI Lib and Photos',
                    img: require('@/assets/images/svg/folder.svg'),
                    link: 'Manage Storage'
                },
                {
                    title: 'Take the Privacy Checkup',
                    subTitle:
                        'This step-by-step guide helps you choose the privacy settings that are right for you',
                    img: require('@/assets/images/svg/data-security-2.svg'),
                    link: 'Get started'
                }
            ]
        }
    }
}
