//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import { items } from "../../data/navigationThree";
export default {
  data() {
    return {
      selectedItem: 0,
      group: null,
      items: items,
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    },
    getMiniVariant() {
      return this.miniVariant;
    },
    computedItems() {
      // return this.items.map(this.mapItem);
      return this.items;
      // console.log(this.items);
      // return this.items;
    },
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = !this.expandOnHover;
      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
        // to: !item.to ? undefined : `/app/${this.item.group}/${item.to}`,
      };
    },
  },
};
