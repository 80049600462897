//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Forgot',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Forgot'
    }
}
