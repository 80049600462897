//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import axios from 'axios'
export default {
    components: {
        CartDrawer: () => import('./CartDrawer')
    },
    computed: {
        ...mapGetters(['getCartList'])
    },
    data: () => ({
        drawer: null,
        cartDrawer: false,
        cartCount: 0,
        current: 1,
        paginate: 6,
        paginate_total: 0,
        isColumnTwelve: false,
        isColumnFour: true,
        radios: 'radio-1',
        productRating: 3.5,
        productList: [],
        filterProductList: [],
        categories: [],
        cartList: [],
        brands: [],
        rating: [],
        selected: [],
        isListView: false,
        items: [
            {title: 'Click Me'},
            {title: 'Click Me'},
            {title: 'Click Me'},
            {title: 'Click Me 2'}
        ],

        // search
        loading: false,
        items: [],
        search: null,
        select: null
    }),
    created() {
        axios.get('/api/ecommerce/get-product-list').then((response) => {
            this.productList = response.data
            this.filterProductList = response.data
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        })
        axios
            .get('/api/ecommerce/get-category-list')
            .then((response) => (this.categories = response.data))
        axios
            .get('/api/ecommerce/get-brand-list')
            .then((response) => (this.brands = response.data))
        axios
            .get('/api/ecommerce/get-rating-list')
            .then((response) => (this.rating = response.data))
    },
    lessThan() {
        this.filterProductList = this.productList.filter((x) => x.price < 500)
        if (this.current > 1) this.current = 1
    },
    moreThan() {
        this.filterProductList = this.productList.filter((x) => x.price >= 500)
        if (this.current > 1) this.current = 1
    },
    filterCategory(category) {
        this.filterProductList = this.productList.filter((x) =>
            this.selected.includes(x.category)
        )

        if (this.selected.length <= 0) {
            this.filterProductList = this.productList
        }
        if (this.current > 1) this.current = 1
        this.paginate_total = Math.ceil(
            this.filterProductList.length / this.paginate
        )
    },

    gridView() {
        this.isListView = false
    },
    listView() {
        this.isListView = true
    },

    methods: {
        ...mapActions(['addCart']),
        totalAmount() {
            let total = 0
            return (total += this.getCartList.reduce(
                (left, cur) => left + cur.price * cur.qty,
                0
            ))
        },
        addCartx(item) {
            this.addCart(item)
            if (item.qty == 1) this.cartCount++
        },
        setPaginate(i) {
            if (this.current == 1) {
                return i < this.paginate
            } else {
                return (
                    i >= this.paginate * (this.current - 1) &&
                    i < this.current * this.paginate
                )
            }
        },
        updateCurrent(i) {
            this.current = i
        },
        productRate(rate) {
            this.filterProductList = this.productList.filter(
                (x) => x.rating == rate.rates
            )

            if (this.current > 1) this.current = 1
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        },
        allProductPrice() {
            this.filterProductList = this.productList.filter(
                (x) => x.price >= 0
            )
            if (this.current > 1) this.current = 1
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        },
        lessThan() {
            this.filterProductList = this.productList.filter(
                (x) => x.price < 500
            )
            if (this.current > 1) this.current = 1
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        },
        moreThan() {
            this.filterProductList = this.productList.filter(
                (x) => x.price >= 500
            )
            if (this.current > 1) this.current = 1
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        },
        filterCategory(category) {
            this.filterProductList = this.productList.filter((x) =>
                this.selected.includes(x.category)
            )

            if (this.selected.length <= 0) {
                this.filterProductList = this.productList
            }
            if (this.current > 1) this.current = 1
            this.paginate_total = Math.ceil(
                this.filterProductList.length / this.paginate
            )
        },

        gridView() {
            this.isListView = false
        },
        listView() {
            this.isListView = true
        },
        querySelections(v) {
            this.loading = true
            // Simulated ajax query
            setTimeout(() => {
                this.items = this.states.filter((e) => {
                    return (
                        (e || '')
                            .toLowerCase()
                            .indexOf((v || '').toLowerCase()) > -1
                    )
                })
                this.loading = false
            }, 500)
        }
    }
}
