//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EInfoCard from '@/components/card/BasicInfoCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Account People'
    },
    components: {
        EInfoCard
    },
    data() {
        return {
            imgOne: require('@/assets/images/svg/contact.svg'),
            imgTwo: require('@/assets/images/svg/placeholder.svg'),
            items: [
                {
                    icon: require('@/assets/images/svg/add-contact.svg'),
                    title: 'Contacts',
                    titleTwo:
                        'No contacts yet, but some contact info saved for autocomplete suggestions'
                },

                {
                    icon: require('@/assets/images/svg/customer.svg'),
                    title: 'Contact info saved from interactions',
                    iconTwo: 'mdi-minus-circle',
                    titleTwo: 'Paused',
                    iconColor: 'secondary'
                },
                {
                    icon: require('@/assets/images/svg/mobile-banking.svg'),
                    title: 'Contact info from your devices',
                    iconTwo: 'mdi-check-circle',
                    titleTwo: 'On',
                    iconColor: 'primary'
                },

                {
                    icon: require('@/assets/images/svg/block.svg'),
                    title: 'Blocked',
                    iconTwo: '',
                    titleTwo: 'No Blocked User',
                    iconColor: ''
                }
            ]
        }
    }
}
