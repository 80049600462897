//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        // Floating Button icon Color
        avatar: {
            type: String,
            default: require('@/assets/images/avatars/004-bald.svg')
        },
        title: {
            type: String,
            default: 'Timothy Clarkson'
        },
        subTitle: {
            type: String,
            default: 'Vue Developer'
        },
        footerBtn: {
            type: String,
            default: 'Chat'
        },
        footerBtnTwo: {
            type: String,
            default: 'Profile'
        },
        buttonColorOne: {
            type: String,
            default: ''
        },
        buttonIconOne: {
            type: String,
            default: ''
        },
        buttonIconTwo: {
            type: String,
            default: ''
        },
        buttonColorTwo: {
            type: String,
            default: ''
        },
        buttonIconThree: {
            type: String,
            default: 'mdi-google'
        },
        buttonColorThree: {
            type: String,
            default: ''
        },
        progressLinearColor: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {}
    }
}
