//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            breadcrumbList: []
        }
    },
    mounted() {
        this.updateList()
    },
    watch: {
        $route() {
            this.updateList()
        }
    },
    methods: {
        routeTo(pRouteTo) {
            if (this.breadcrumbList[pRouteTo].link)
                this.$router.push(this.breadcrumbList[pRouteTo].link)
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        }
    }
}
