import axios from 'axios';
export default (to, from, next) => {
  if (
    localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0
  ) {
    
    let user_info = localStorage.getItem('userInfo')
    user_info = JSON.parse(user_info)
    
    let config = { headers: {'Authorization': 'Bearer ' + user_info.idtoken}}
    axios.get('https://www.tsukinami-system.com/fastapi/protected', config)
    .then(response => {
      next()
    })
    .catch(error => {
      console.log(error)
      alert("認証に失敗しました1")

      let config = { headers: {'Authorization': 'Bearer ' + user_info.refresh_token}}
     
      axios.get('https://www.tsukinami-system.com/fastapi/refresh', config)
      .then(response => {
        //alert("認証に成功しました2")

        localStorage.removeItem('userInfo') //リフレッシュトークン取得後のものに変えるために過去のものは消去
        let data = response.data;
        const userInfo = {uid:data.uid, username:data.username, administrator:data.administrator, 
                          idtoken:data.idtoken, access_token:data.access_token, refresh_token:data.refresh_token }
        
        localStorage.setItem('userInfo', JSON.stringify(userInfo))

        next()
      })
      .catch(error => {
        //console.log(error)
        //alert("認証に失敗しました2")
        localStorage.removeItem('userInfo')
        next('/app/sessions/sign-in-two')
      })

      //localStorage.removeItem('userInfo')
      //next('/app/sessions/sign-in-two')
    })

  } else {
    
    localStorage.removeItem('userInfo')
    next('/app/sessions/sign-in-two')
  }
}
