//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
    name: 'Todo',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Todo'
    },
    components: {
        VuePerfectScrollbar
    },
    created() {
        axios.get('/api/todo/').then((response) => {
            this.todos = response.data
            //  console.log(this.todos);
        })
    },
    data: () => ({
        todoSearch: '',
        todos: [],
        selected: [''],
        //////-----/////
        snackbar: false,
        isOpen: false,
        isBlock: false,
        checkbox: false,
        dialog: false,
        isLineThrough: false,
        text: 'Task Name Must Be Required...',
        timeout: 2000,
        // item: 1,
        // itemsTwo: 0,
        drawer: null,
        newEditTodo: '',
        newTodo: {},

        personsInfo: [
            {
                image: require('@/assets/images/avatars/001-man.svg'),
                name: 'Timothy Clarkson'
            },
            {
                image: require('@/assets/images/avatars/002-woman.svg'),
                name: 'Medusa'
            }
        ],
        personAvatar: [
            require('@/assets/images/avatars/001-man.svg'),
            require('@/assets/images/avatars/002-woman.svg')
        ],

        items: [
            {text: 'Favorite', icon: 'mdi-star-outline'},
            {text: 'Important', icon: 'mdi-alert-decagram-outline'},
            {text: 'Completed', icon: 'mdi-account-check-outline'},
            {text: 'Delete Files', icon: 'mdi-delete-outline'}
        ],
        itemsTwo: [
            {
                text: 'Frontend',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'primary'
            },
            {
                text: 'Backend',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'orange'
            },
            {
                text: 'Doc',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'success'
            },
            {
                text: 'Bug',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'danger'
            }
        ],
        chips: [
            'Programming',
            'Playing video games',
            'Watching movies',
            'Sleeping'
        ],
        itemsChip: ['Streaming', 'Eating']
    }),
    methods: {
        addTodo: function() {
            axios
                .post('/api/todo/add', {data: this.newTodo})

                .then((response) => {
                    this.todos.push({
                        title: this.newTodo.msg,
                        desc: this.newTodo.taskName,
                        tags: this.newTodo.items,
                        avatar: this.newTodo.personInfo
                    })
                })
            this.dialog = false
        },
        // checkTodo: function(todo){
        //   axios.patch(`/api/todos/${todo.id}`, {data: todo})
        // },
        removeTodo: function(todo) {
            // this.todos.splice(index, 1)
            let {id} = todo

            axios.post('/api/todo/delete', {id}).then((response) => {
                this.todos = this.todos.filter((todo) => todo.id !== id)
            })
        },
        editTodo: function(todo) {
            this.newEditTodo = todo
            // console.log(todo)
        },
        doneEditTodo: function(todo) {
            // console.log(todo);
            axios.post('/api/todo/update', {todo}).then((response) => {
                this.todos = this.todos.map((todo) => {
                    if (todo.id == response.data.id) {
                        console.log(response.data, 'frontend')
                        this.drawer = !this.drawer
                        return response.data
                    } else return todo
                })
            })
        },
        textDecoration: function(todo) {
            todo.isCompleted = !todo.isCompleted
        },
        appToggle: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        overlayApp: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        }
    },
    computed: {
        filterSearchList() {
            return this.todos.filter((post) => {
                return post.title
                    ?.toLowerCase()
                    .includes(this.todoSearch?.toLowerCase())
            })
        }
    }
}
