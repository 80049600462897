//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Lockscreen',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Lockscreen'
    },
    data() {
        return {
            show: false,
            password: 'Password'
        }
    }
}
