//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard'
import {
    basicBarChart,
    groupedBar,
    stackedbar,
    negativeBar,
    customDataLabelBar,
    patternedBar
} from '@/data/apexChart'
export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Apex- Bar Charts'
    },
    components: {
        ChartCard
    },
    data() {
        return {
            name: 'apexBarChart',
            basicBarChart,
            groupedBar,
            stackedbar,
            negativeBar,
            customDataLabelBar,
            patternedBar
        }
    }
}
