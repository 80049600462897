//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import encoding from "encoding-japanese";
import csvToJson from "convert-csv-json";
import ExcelJS from 'exceljs';
import axios from 'axios';

var Vue = require('vue')

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: '帳簿'
    },
    created () {
 
    //ヘッダー入れる処理
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
    this.selectedtypes = this.payment_selections;
    window.addEventListener("beforeunload", this.confirmSave);
  },
    destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
    mounted() {
        //データ取得API関数実行
        this.getEvents()
        this.getMembers()

        //cash_flow_infoの初期値入れとく処理
        this.original_val = JSON.parse(JSON.stringify(this.cash_flow_info));
  },
  computed: {
    //Done to get the ordered headers
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
   
  },
    data() {
        return {

        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        dialog_type: '',
            search: '',
            dialog: false,
            selected: [],
            headers: [],
            headersMap: [
                {text: '内容', value: 'title'},
                {text: '内容詳細', value: 'content'},
                {text: '寄付者', value: 'member_name'},
                {text: '金額', value: 'amount'},
                {text: '日付', value: 'date'},
                {text: '備考', value: 'memo'},
                {text: '責任者確認', value: 'confirmation'},
                {text: '編集', value: 'action'}
            ],
            member_id:"",
            new_cash_name:"",
            new_cash_content:"",
            new_cash_member_name:"",
            new_cash_amount:"", 
            new_cash_memo:"",
            new_cash_confirmation:false,
            new_cash_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu:"",
            // 入力規則
            required: value => !!value || "必ず入力してください", // 入力必須の制約
            EditModeActive: false,
            charcode: "UTF8", //デフォルト文字コード
            selectedHeaders: [],
            payment_selections: [
                '護持会費',
                '護持会寄付',
                '納骨堂年間冥加金',
                '納骨堂寄付',
                '境内墓地年間冥加金',
                '境内墓地寄付',
                '合同墓納骨日',
                'お布施',
                'その他',
            ],
            selectedtypes: [],
            original_val:[], // 初期値を入れとく変数
            upload_file:[], //uploadしたfile
            cash_flow_info: [
                // { date: '2022/01/01',
                //   member_name: "田仲商大",
                //   title: '護持会費',
                //   content: '毎月の護持会費',
                //   amount: 3000,
                //   memo:"特になし",
                //   confirmation: true,
                // },
                // { date: '2021/01/01',
                //   member_name: "田仲商大",
                //   title: '納骨堂年間冥加金',
                //   content: 'その他',
                //   amount: 10000,
                //   memo:"納骨堂のお金",
                //   confirmation: false,
                // }
              ],
            member_names:[],
            
        }
    },

    beforeRouteLeave (to, from , next) {
        
            const Equality = this.isEqualArray()
            //console.log(Equality)

            if(Equality == false){
               
                const answer = window.confirm('新たに編集された帳簿のデータは保存されていません。未保存のまま帳簿を閉じてもよろしいでしょうか？')

                if (answer) {  next() }else{ next(false) }
            }else{next()}
},


methods: {
      jump () {
         
         window.open('https://www.tsukinami-system.com/app/profile/profile-one', '_blank'); // 新しいタブを開き、ページを表示
        
      },
      remove (cash_flow_info) {

         // console.log(cash_flow_info.membership_number)
         const index = this.cash_flow_info.indexOf(cash_flow_info)
         const answer = window.confirm(cash_flow_info.title + "を帳簿から削除してもよろしいでしょうか？")
         if(answer){
            this.cash_flow_info.splice(index, 1)
         }else{ return }
      },     

       active: function () {
        let Equality = this.isEqualArray()

        // 編集完了し、編集内容を保存するか確認 
        if(this.EditModeActive == true && Equality == false){

            const answer1 = window.confirm("編集内容を保存してもよろしいでしょうか？")
            if(answer1){

                let user_info = localStorage.getItem('userInfo')
                user_info = JSON.parse(user_info)
                let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}
                console.log(this.cash_flow_info)
                //DB編集APIを投げる
                axios.post('https://www.tsukinami-system.com/fastapi/account_books', this.cash_flow_info, config)
                    .then((response) => {
                      console.log(response);
                    }, (error) => {
                      console.log(error);
                    });

            
                //cash_flow_infoの初期値を更新する
                this.original_val = JSON.parse(JSON.stringify(this.cash_flow_info));
                
                // ボタンを変える
                this.EditModeActive = !this.EditModeActive;
            }else{ 
                const answer2 = window.confirm("編集内容を破棄して編集モードを終了しますか？")
                 if(answer2){
                    //編集内容を初期値に戻す
                    this.cash_flow_info = JSON.parse(JSON.stringify(this.original_val)); 
                    // ボタンを変える 
                    this.EditModeActive = !this.EditModeActive;
                 }else{
                    //何もせずに戻る
                    return
                 }
        }
        }else{
        // ボタンを変える 
        this.EditModeActive = !this.EditModeActive;
        }
       }
    ,
    getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
},


      save () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = '編集モード終了'
      },
       
      open () {
        this.snack = true
        this.snackColor = 'purple'
        this.snackText = '編集モード開始'
      },
    
      
      clickconfirmation (item) {
     // ボタンを押下でtrue,falseを変える処理
      if(item.confirmation){
      item.confirmation = false
      }else{
      item.confirmation = true

      }
    },
    clickletter (item) {
     // ボタンを押下でtrue,falseを変える処理
      if(item.letter){
      item.letter = false
      }else{
      item.letter = true

      }
    },

      isEqualArray () {
      // 連想配列の中身が一致しているか確認するメソッド
        let old_array = this.original_val
        let new_array = this.cash_flow_info 

        if(new_array.length ==  old_array.length){
            //数が一致しているかをまず確認
            for(let i = 0; i < new_array.length; i++){ 
                let new_line = new_array.slice(i, i+1)
                let old_line = old_array.slice(i, i+1)
                new_line = new_line[0]
                
                old_line = old_line[0]
                for (let key in new_line) {
                    //console.log('key:' + key + ' value:' + new_line[key]);
                    if(new_line[key] != old_line[key]){
                        // 中身が異なる
                        return false
                    }else{
                        continue; }}
        } return true
        }else{
            // 連想配列の数が異なる
            return false
             }
    },

    async onExport(ext) {

        
      // ①初期化
      const workbook = new ExcelJS.Workbook(); // workbookを作成
      workbook.addWorksheet('帳簿'); // worksheetを追加
      const worksheet = workbook.getWorksheet('帳簿'); // 追加したworksheetを取得

      // ②データを用意
      // 各列のヘッダー
      worksheet.columns = [
        { header: '内容', key: 'title' },
        { header: '内容詳細', key: 'content' },
        { header: '寄付者', key: 'member_name' },
        { header: '金額', key: 'amount' },
        { header: '日付', key: 'date' },
        { header: '備考', key: 'memo' },
        { header: '責任者確認', key: 'confirmation' },
      ];

      let cash_flow_info_data = JSON.parse(JSON.stringify(this.cash_flow_info));

      // true/falseを言葉に変換処理
      cash_flow_info_data.forEach((data) => {

        // excel csvがゼロ落ちする問題... 先頭にアポストロフィいれるべきか否か
        // 消えるのが目に見えているので一旦いれておく(日本人はエクセル好き)
        data.phone_number = "'" + data.phone_number  
       // data.phone_number = '="' + data.phone_number + '"'

        
        if(data.confirmation){
            data.confirmation= "確認済み"

        }else{
            data.confirmation = "未確認"

        }

    });

      // 各行のデータ（worksheet.columnsのkeyがオブジェクトのキーと同じになる）
      worksheet.addRows(cash_flow_info_data); // memver_infoを入れる
      let charcode = this.charcode

      // ③ファイル生成
      const uint8Array =
        ext === 'xlsx'
          ? await workbook.xlsx.writeBuffer() // xlsxの場合
          : charcode === "UTF8"
          ? await workbook.csv.writeBuffer() // csvの場合
          : new Uint8Array(
            encoding.convert(await workbook.csv.writeBuffer(), {
              from: "UTF8",
              to: "SJIS"
            })
          );

      const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      const a = document.createElement('a');
      a.href = (window.URL || window.webkitURL).createObjectURL(blob);
      a.download = `帳簿.${ext}`;
      a.click();
      a.remove()
    
  },
    loadCsvFile() {
      let result = [];
    
      //let file = e.target.files[0];
      let file = this.upload_file

      var sjisArray = new Uint8Array(file );


      if (!file.type.match("text/csv")) {
      //  vm.message = "CSVファイルを選択してください";
        return;
      }

      let reader = new FileReader();
     // reader.readAsText(file, "SJIS");
     //reader.readAsText(file);

      let items = [];
      reader.onload = () => {

        // ファイル読み込み
        let fileResult =reader.result.split('\n');

        
        // 先頭行をヘッダとして格納
        let header = fileResult[0].split(',')
        
        // 先頭行の削除
        fileResult.shift();
        // 全てを格納する箱
        let linesArr = [];
        for (let i = 0; i < fileResult.length; i++) {
            // console.log(fileResult[i])
            linesArr[i] = fileResult[i].split(",");  
          
            };


        var box = [];
        //console.log(this.cash_flow_info)

        const mapValue = linesArr.map(value => {
            if(value[0]==""){
                return
            }else{
            var data_tmp = {};

            //　要素を多次元連想配列にいれていく
            // Validation処理必須！！！！！！！！！！！！！！！！！！！！今後追加！！！！
            
            data_tmp.title = value[0];  //内容
            data_tmp.content = value[1];   //内容詳細
            data_tmp.member_name = value[2];   //支払い人
            data_tmp.amount = value[3];   //金額
            data_tmp.date = value[4];    //日付
            data_tmp.memo = value[5]; 

            
            // 送付はブーリアン型なので変換（空白除去）
            if(value[6].trim() =="確認済み"){
                data_tmp.confirmation = true;
            }else{
                data_tmp.confirmation = false;
            }
            
            box.push(data_tmp);
            }
        
            return value;
});
       
        this.cash_flow_info = box

      }
      
    },
        async getFileContent () {
      try {
        const content = await this.readFileAsync(this.upload_file)  
       
        const cleaned_data = this.csvJSON(content)
        this.cash_flow_info = cleaned_data



      } catch (e) {
        console.log(e)
      }
    },
   
   readFileAsync (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          //文字列をバイト列に変換  
          var sjisArray = new Uint8Array(reader.result);
          //encode.jsを使い、文字コードを自動判別しUNICODEへ変換
          var unicodeArray = encoding.convert(sjisArray, {
            to: 'UNICODE',
            from: 'AUTO',
            type: 'string'
          });
          resolve(unicodeArray);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },
    

    confirmSave (event) {
            const Equality = this.isEqualArray()
            if(Equality == false){
                event.preventDefault();
                event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
            }
    },

csvJSON(csv){

  var lines=csv.split("\n");

  var result = [];

  var headers=lines[0].split(",");


  headers =  ['title', 'content' , 'member_name', 'amount' ,'date', 'memo' ,'confirmation' ]

  for(var i=1;i<lines.length;i++){

      var obj = {};
      
      var currentline=lines[i].split(",");

      for(var j=0;j<headers.length;j++){
          ///obj[headers[j]] = currentline[j];
          //obj = {"membership_number":  currentline[j]}
          obj[headers[j]]= currentline[j]

      }
      
      result.push(obj);

  }
            
   // データ整形処理
  for (let count = 0; count < result.length; count++) {
            Object.keys(result[count]).forEach(function(key) {
         
            // console.log(key + '=' + result[count][key]);
            if(result[count][key] == null){ return
            }

            if(key == "confirmation"){
                if(result[count].confirmation.trim() == "確認済み"){result[count].confirmation = true
                }else{result[count].confirmation = false
                }
            }
            });
        }

  return result

},

readXlsxData(){
    
  let columnsFromHeader = true
  var result =[];
  const workbook = new ExcelJS.Workbook();
  
  workbook.xlsx.load(this.upload_file).then(function(){
  let headers =  ['title', 'content' , 'member_name', 'amount' ,'date', 'memo' ,'confirmation' ]

    //最初のsheetを読み込んでいる
    const sheet = workbook.getWorksheet(1);
    //console.log(sheet)
    let columIndex= headers.length +1; // 読み込む列の数をここで設定する
    let rowIndex=1; //読み込み開始行
    if(columnsFromHeader){

      columIndex--;
      rowIndex++;
      
      while(sheet.getCell(rowIndex, 1).value !== null){
        var data ={};
        
        let col=1;
        while(col<=columIndex){
          data[headers[col-1]] =sheet.getCell(rowIndex, col++).value;
        }
        result.push(data);
        rowIndex++;
      }
    }else{

        return 
    }

     // データ整形処理
  for (let count = 0; count < result.length; count++) {
            Object.keys(result[count]).forEach(function(key) {
         
            // console.log(key + '=' + result[count][key]);
            if(result[count][key] == null){return}

            if(key == "confirmation"){
                if(result[count].confirmation.trim() == "確認済み"){ result[count].confirmation = true
                }else{ result[count].confirmation = false}
            }
            });
            }   
    return(result); 
  })
  return result
  // this.cash_flow_info = result // ここでデータを書き換える
  },


    async uploadController () {

        if(!this.upload_file){
            return 
        }

        let file_type = this.upload_file.name.split('.').pop();
        if(file_type == "csv"){
                  try {
                        const content = await this.readFileAsync(this.upload_file)  
                        const cleaned_data = this.csvJSON(content)
                        // 書き換える
                        this.cash_flow_info = cleaned_data

                    } catch (e) {
                        console.log(e)
                    }


        }else if(file_type == "xlsx"){

                    try {
                        const content = await this.readXlsxData()
                        // 書き換える
                        this.cash_flow_info = content
                        

                    } catch (e) {
                        console.log(e)
                    }

        }


    },
    getHashMaxNumber(hash, keyName) {
	    var maxNumber;
      for (var i = 0, len = hash.length; i < len; i++) {
        if (!hash[i][keyName]) continue;
        if (typeof maxNumber === 'undefined' || hash[i][keyName] > maxNumber) {
          maxNumber = hash[i][keyName];}}
        return maxNumber;
      },

    new_cash_info_add(){

        if (this.$refs.input_form.validate()) {

          let new_id_num;
          if (this.cash_flow_info.length === 0) {
          new_id_num = 1
          }else{
          new_id_num = this.getHashMaxNumber(this.cash_flow_info, "event_id") + 1 //カレンダーIDの中で最大の値に1を加算させる
          }
        const formattedDate = this.new_cash_date.replace(/-/g, "/");
        let member_name;
        let member_id;

        // member_IDを検索する 門徒かどうかチェック カレンダーと門徒を紐づけ処理
        const isFound = this.member_names.includes(this.new_cash_member_name);
        if(isFound  == true){
        let str = this.new_cash_member_name;
        let [str_member_id, str_member_name] = str.split('　');
        member_name =  str_member_name
        member_id = str_member_id
        }else{
        member_id = ""
        }

        var new_cash_flow_info = {
                    event_id: new_id_num,
                    member_id: member_id,
                    title: this.new_cash_name,
                    content: this.new_cash_content,
                    member_name: member_name,
                    amount: this.new_cash_amount,
                    memo: this.new_cash_memo,
                    date: formattedDate,
                    confirmation: this.new_cash_confirmation
                }

        this.cash_flow_info.push(new_cash_flow_info);
        this.dialog =false
        this.clear()

        }{return}

    },

    clear () {
        this.$refs.input_form.resetValidation()
        this.new_cash_name=""
        this.new_cash_content=""
        this.new_cash_member_name=""
        this.new_cash_amount="",
        this.new_cash_memo="",
        this.new_cash_confirmation=false
        this.new_cash_date= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },


    toJapaneseConfirmation(bool){
        if(bool){return "責任者確認済み"}else{return "責任者未確認"}
      },
    toJapaneseSendletter(bool){
        if(bool){return "送付"}else{return "未送"}
      },

    getMembers() {
        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}

        axios.get('https://www.tsukinami-system.com/fastapi/members', config)
        .then(response => {
        console.log(response.data)

        // 成功時の処理etc
        let event_data = response.data
        let event_list = new Array();
        console.log(event_data)

        for(let i = 0; i < event_data.length; i++){
            console.log(i)

            //let member_id =              
            let name = String(event_data[i].id) +"　" + String(event_data[i].name)
        event_list.push(name);
        }
        this.member_names = event_list

        return response
        })
        .catch(error => {
        // 失敗時の処理etc
        alert(error)
        return error
        })
        },

    
    getEvents() {
        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        let config = { headers: {'Authorization': 'Bearer ' + user_info.access_token}}



    // ここでまず取得用APIを呼び出す
    axios.get('https://www.tsukinami-system.com/fastapi/account_books/', config)
    .then(response => {
    console.log(response.data)
    
    // 成功時の処理etc
    let event_data = response.data
    let event_list = new Array();
    console.log(event_data)

    for(let i = 0; i < event_data.length; i++){
        console.log(i)

        let event={
        event_id: event_data[i].id, 
        member_id: event_data[i].member_id,
        content: event_data[i].content,
        member_name:event_data[i].member_name,               
        title:event_data[i].title,
        amount:event_data[i].amount,
        memo: event_data[i].memo,
        date: new Date(event_data[i].date).toLocaleDateString(),
        confirmation: event_data[i].confirmation,
    
    }
    console.log(event)
    event_list.push(event);
    }
    this.cash_flow_info= event_list
    
    //ここでデータ保持
    this.original_val = JSON.parse(JSON.stringify(this.cash_flow_info)); 

    

    return response
    })
    .catch(error => {
    // 失敗時の処理etc
    alert(error)
    return error
    })
    },

}
}


