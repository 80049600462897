//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UserDrawer',
    props: {},
    data() {
        return {
            user_name:"",
            administrator: false,
        }
    },
    computed: {},
    created() {
    
        this.loadFromLocalStorage()
    },
    methods: {

        loadFromLocalStorage() {
        let user_info = localStorage.getItem('userInfo')
        user_info = JSON.parse(user_info)
        this.user_name = user_info["username"]
        this.administrator = user_info["administrator"]
        }

    }
}
