//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard'
import {
    lineColumn,
    multipleYxis,
    lineArea,
    lineColumnArea
} from '@/data/apexChart.js'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Apex- Mix Charts'
    },
    components: {
        ChartCard
    },
    data() {
        return {
            lineColumn,
            multipleYxis,
            lineArea,
            lineColumnArea
        }
    }
}
