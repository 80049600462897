//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            items: [
                {
                    iconName: 'mdi-cloud-upload',
                    iconColor: 'bg-blue-200',
                    iconColorText: 'info--text',
                    title: 'Watson uploaded a file',
                    file: require('@/assets/images/file-types/001-pdf.svg'),
                    fileName: 'progress_report.pdf',
                    time: '15 min ago'
                },
                {
                    iconName: 'mdi-file-document',
                    iconColor: 'bg-blue-200',
                    iconColorText: 'info--text',
                    title: 'jhon Commented on task',
                    file: require('@/assets/images/file-types/001-pdf.svg'),
                    fileName: 'progress_report.pdf',
                    time: '5 min ago'
                },
                {
                    iconName: 'mdi-comment-alert',
                    iconColor: 'bg-orange-200',
                    iconColorText: 'warning--text',
                    title: 'jhon Commented on task',

                    time: '45 min ago'
                },
                {
                    iconName: 'mdi-cloud-upload',
                    iconColor: 'bg-blue-200',
                    iconColorText: 'info--text',
                    title: 'jhon Commented on task',
                    file: require('@/assets/images/file-types/004-xlsx.svg'),
                    fileName: 'budget_report.xlsx',
                    time: '15 min ago'
                },
                {
                    iconName: 'mdi-comment-alert',
                    iconColor: 'bg-orange-200',
                    iconColorText: 'warning--text',
                    title: 'jhon Commented on task',

                    time: '45 min ago'
                }
            ]
        }
    }
}
