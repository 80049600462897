//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard';
import {
  basicRadar,
  radarMultiSeries,
  radarwithPolygonFill
} from "@/data/apexChart.js";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Apex- Radar Charts"
  },
  components:{
    ChartCard
  },
  data() {
    return {
      basicRadar,
      radarMultiSeries,
      radarwithPolygonFill
    };
  }
};
