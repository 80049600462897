export const items = [

  {
    title: "カレンダー",
    icon: "mdi-calendar",
    group: "customTable",
    divider: "機能",
    children: [
      {
        title: "スケジュール管理",

        to: "calendars",
        icon: "mdi-circle-medium",
      },
      {
        title: "予定一覧",

        to: "scheduletable",
        icon: "mdi-circle-medium",
      },
    ],
  },

  {
    title: "名簿",
    icon: "mdi-table-large",
    group: "customTable",
    children: [
      {
        title: "門徒名簿",

        to: "tableOne",
        icon: "mdi-circle-medium",
      },
     
      {
        title: "寺務員名簿",

        to: "workmembertable",
        icon: "mdi-circle-medium",
      },
      // {
      //   title: "門徒カルテ",

      //   to: "profile-one",
      //   icon: "mdi-circle-medium",
      // },
    ],
  },
  {
    title: "帳簿管理",
    icon: "mdi-fountain-pen-tip",
    group: "customTable",
    children: [
      {
        title: "帳簿",

        to: "tableTwo",
        icon: "mdi-circle-medium",
      },
      {
        title: "帳簿追加",

        to: "moneyinput",
        icon: "mdi-circle-medium",
      }
     
    ],
  },

  {
    title: "付箋",
    icon: "mdi-bookmark",
    group: "note",
    children: [
      {
        title: "付箋",
        icon: "mdi-circle-medium",
        to: "note",
        icon: "mdi-circle-medium",
      },
    ],
  }, 
  {
    title: "アカウント設定",
    icon: "mdi-account",
    group: "account",
    divider: "設定",
    children: [
      {
        title: "アカウント情報",
        to: "AccountData",
        icon: "mdi-circle-medium",
      },
      {
        title: "利用プラン",
        to: "pricing-ver-two",
        icon: "mdi-circle-medium",
      },
      {
        title: "良く聞かれる質問について",
        to: "FaqTwo",
        icon: "mdi-circle-medium",
      },
    
    
    ]
    },

];
