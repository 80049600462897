//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'
export default {
    name: 'Scrumboard',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Scrumboard'
    },
    components: {
        draggable
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false
            }
        }
    },
    data() {
        return {
            list1: [
                {
                    name:
                        'Lorem Ipsum is simply dummy text of the printing and One',
                    id: 1,
                    img: require('@/assets/images/faces/1.jpg')
                },
                {
                    name:
                        'Lorem Ipsum is simply dummy he printing and typesetting Two',
                    id: 2,
                    img: require('@/assets/images/faces/2.jpg')
                },
                {
                    name:
                        'Lorem Ipsum is simply dummy text of  printing and typesetting Three',
                    id: 3,
                    img: require('@/assets/images/faces/3.jpg')
                },
                {
                    name:
                        'Lorem Ipsum is simply  text of the printing and typesetting Four',
                    id: 4,
                    img: require('@/assets/images/faces/4.jpg')
                }
            ],
            list2: [
                {
                    name:
                        'Lorem Ipsum is simply dummy text of the printing and typesetting Five',
                    id: 5,
                    img: require('@/assets/images/faces/4.jpg')
                },
                {
                    name:
                        'Lorem Ipsum is simply dummy he printing and typesetting Six',
                    id: 6,
                    img: require('@/assets/images/faces/15.jpg')
                },
                {
                    name:
                        'Lorem Ipsum is simply  text of the printing and typesetting Seven',
                    id: 7,
                    img: require('@/assets/images/faces/16.jpg')
                }
            ],
            oldIndex: '',
            newIndex: ''
        }
    }
}
