//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarGroupCard from "@/components/card/AvatarGroupCard";
export default {
  name: "General",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "General",
  },
  components: {
    AvatarGroupCard,
  },
  data() {
    return {
      slider: 0,
      sliderTwo: 0,
      sliderThree: 0,
      sliderFour: 0,
      sliderFive: 0,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      generals: [
        {
          title: "Presentation",
          subTitle: "New York",
          paragraph:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Dummy text of the printing",
          avatar: "mdi-briefcase-variant-outline",
          avatarColor: "blue lighten-5",
          avatarIconColor: "blue--text text--darken-1",
        },
        {
          title: "Holiday",
          subTitle: "Los Angeles",
          paragraph:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Dummy text of the printing",
          avatar: "mdi-umbrella",
          avatarColor: "green lighten-5",
          avatarIconColor: "green--text text--darken-1",
        },
        {
          title: "Local Activity",
          subTitle: "Michigan",
          paragraph:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Dummy text of the printing",
          avatar: "mdi-account-check-outline",
          avatarColor: "red lighten-5",
          avatarIconColor: "red--text text--darken-1",
        },
      ],
    };
  },
};
