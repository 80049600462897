//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Sliders'
    },
    data() {
        return {
            min: -50,
            max: 90,
            slider: 40,
            range: [-20, 70],
            media: 0,
            alarm: 0,
            zoom: 0,
            value: 10,
            value2: [20, 40],
            satisfactionEmojis: [
                '😭',
                '😢',
                '☹️',
                '🙁',
                '😐',
                '🙂',
                '😊',
                '😁',
                '😄',
                '😍'
            ],
            fruits: 0,
            ticksLabels: ['Figs', 'Lemon', 'Pear', 'Apple'],
            ex1: {label: 'color', val: 25, color: 'orange darken-3'},
            ex2: {label: 'track-color', val: 75, color: 'green lighten-1'},
            ex3: {label: 'thumb-color', val: 50, color: 'red'},
            rules: [(v) => v <= 40 || 'Only 40 in stock'],
            bpm: 40,
            interval: null,

            isPlaying: false
        }
    },
    methods: {
        zoomOut() {
            this.zoom = this.zoom - 10 || 0
        },
        zoomIn() {
            this.zoom = this.zoom + 10 || 100
        }
    }
}
