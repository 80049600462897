//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        AppFooter: () => import('./Footer'),
        TopNavbar: () => import('./TopNavbar'),
        TopNavMenu: () => import('./TopNavMenu')
    },
    computed: {
        ...mapGetters(['getThemeMode'])
    },
    data() {
        return {}
    },

    methods: {}
}
