//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard';
import { simpleScatter, scatterDatetime } from "@/data/apexChart.js";
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Apex- Scatter Charts"
  },
  components:{
    ChartCard
  },
  data() {
    return {
      simpleScatter,
      scatterDatetime
    };
  }
};
