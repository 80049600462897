//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  data() {
    return {
      // hover menu plugins one
      menu: [
        {
          // display menu item (or override title slot)
          title: "News",
          // now this is not a link, but a menu item where there is a dropdown
          dropdown: "news",
          content: [
            {
              title: "news 1",
              link: "analytic",
            },
            {
              title: "news 1",
              link: "analytic",
            },
            {
              title: "news 1",
              link: "analytic",
            },
          ],
          // don't want a button element?
          element: "span",
          // menu item can accept all attributes
          attributes: {
            // I want more classes! No problem
            // string, array, object, not matter
            class: ["my-class1", { "my-class2": true }],
            // Custom attributes
            "data-big": "yes",
          },
          // add some events?
          listeners: {
            // all possible native events
            mouseover: (evt) => {
              console.log("news hover", evt);
            },
          },
          // just extra properties in the object
          new_section: false,
        },
        {
          // display menu item (or override title slot)
          title: "test2",
          // now this is not a link, but a menu item where there is a dropdown
          dropdown: "test",
          content: [
            {
              title: "Test 1",
              link: "analytic",
            },
            {
              title: "Test 1",
              link: "sales-2",
            },
            {
              title: "Test 1",
              link: "analytic",
            },
          ],
          // don't want a button element?
          element: "span",
          // menu item can accept all attributes
          attributes: {
            // I want more classes! No problem
            // string, array, object, not matter

            // Custom attributes
            "data-big": "yes",
          },
          // add some events?
          listeners: {
            // all possible native events
            mouseover: (evt) => {
              console.log("test hover", evt);
            },
          },
          // just extra properties in the object
          new_section: false,
        },
        // ...
      ],

      // end hover menu plugins one
    };
  },
  methods: {
    // menu hover plugins one
    onOpenDropdown() {
      console.log("onOpenDropdown");
    },
    onCloseDropdown() {
      console.log("onCloseDropdown");
    },
    // end menu hover plugins one
  },
};
