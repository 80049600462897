//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Pricing ver 3",
  },
  data() {
    return {
      desserts: [
        {
          name: "Plan Cost",
          essential: "Free",
          btn: true,
        },
        {
          name: "Platform",
          essential: "5%",
          premium: "3%",
        },
        {
          name: "Payment processing",
          essential: "2.9%",
          premium: "2.4%",
        },
      ],
      features: [
        {
          name: "Unlimited Fundraising campaigns",
          approve: true,
          approve2: true,
          approve3: true,
        },
        {
          name: "Easy access to funds",
          approve: true,
          approve2: true,
          approve3: true,
        },
        {
          name: "Mobile Optimized",
          approve: true,
          approve2: true,
          approve3: true,
        },
        {
          name: "Basic donor data",
          approve: true,
          approve2: true,
          approve3: true,
        },
        {
          name: "Team fundraising",
          approve: true,
          approve2: true,
          approve3: true,
        },
        {
          name: "Unlimited Fundraising events",
          approve: false,
          approve2: true,
          approve3: true,
        },
        {
          name: "Comphensive donor data",
          approve: false,
          approve2: false,
          approve3: true,
        },
      ],
    };
  },
};
