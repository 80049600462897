//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HoverButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    textHover: {
      type: String,
      default: "",
    },
    bgHover: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    large: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    iconMr: {
      type: String,
      default: "",
    },
    smallIcon: {
      type: Boolean,
      default: false,
    },
    clickEvent: {
      type: String,
      default: "",
    },
  },
};
