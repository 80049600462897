//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { validationMixin } from 'vuelidate' 使ってないからあとで消す
// import { required, maxLength, email } from 'vuelidate/lib/validators'
import axios from 'axios';

// global変数にて最初にAPIにて取得した値を保持
var name = ""
var client = ""
var worker = ""
var zip = ""
var address = ""

export default {
  
  
  // dataで変数の定義を行う
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    name: "", 
    client: "",
    worker: "",
    zip:"",
    address:"",
    select: null,
    items: [
      '会計費',
      'お寺費用',
      '交通費',
      'その他諸々',
    ],
    checkbox: false,
    edit_mode: false, // 編集モードOFF
    errorMessages:"",
    menu:"",
    // 入力規則
    required: value => !!value || "必ず入力してください", // 入力必須の制約
  }),

  created : function(){
  
  // ここで取得用APIを呼び出す
  axios.get('https://jsonplaceholder.typicode.com/users')
        .then(response => this.users = response.data)
  console.log(this)
  console.log('mounted')

  // global変数に代入
  name = "予定名"
  client = "相手予定者前"
  worker = "対応予定者名前"
  zip = "1112222"
  address = "対応先住所"
  
  // 画面に反映
  this.name = name
  this.client = client
  this.worker = worker
  this.zip = zip
  this.address = address
  
},


methods: {
    edit() {
      this.edit_mode = true
      
    },
    submit () {
      this.$refs.input_form.validate()
        if (this.$refs.test_form.validate()) {
        // すべてのバリデーションが通過したときのみ
        // if文の中に入る
        this.success = true;
        } else {
        this.success = false;
        }
      this.edit_mode = false
      
    },
    cancel () {
      this.$refs.input_form.resetValidation()
      this.name = name
      this.address =  address
      this.zip =  zip
      this.client =  client
      this.worker =  worker
      this.select = null
      this.checkbox = false
      this.edit_mode = false
    },
   
  },
}
