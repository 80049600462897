//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        badgeColor: {
            type: String,
            default: ''
        },
        cardColor: {
            type: String,
            default: ''
        },
        badgeTextColor: {
            type: String,
            default: ''
        },
        badgeText: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        textSmall: {
            type: String,
            default: ''
        },
        btnIcon: {
            type: String,
            default: ''
        },
        btnColor: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hover: false
        }
    }
}
