//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Bottom Sheets'
    },
    data() {
        return {
            sheet: false,
            tiles: [
                {img: 'keep.png', title: 'Keep'},
                {img: 'inbox.png', title: 'Inbox'},
                {img: 'hangouts.png', title: 'Hangouts'},
                {img: 'messenger.png', title: 'Messenger'},
                {img: 'google.png', title: 'Google+'}
            ]
        }
    }
}
