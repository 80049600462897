//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    widgetChart,
    widgetChart2,
    widgetChart3,
    widgetChart4,
    widgetChart5,
    widgetChart6,
    widgetChart7,
    widgetChart8,
    widgetChart9,
    widgetChart10,
    widgetChart11,
    widgetChart12,
    widgetChart13,
    widgetChart14
} from '@/data/widgetChart'
export default {
    name: 'Charts',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Charts'
    },

    data() {
        return {
            widgetChart,
            widgetChart2,
            widgetChart3,
            widgetChart4,
            widgetChart5,
            widgetChart6,
            widgetChart7,
            widgetChart8,
            widgetChart9,
            widgetChart10,
            widgetChart11,
            widgetChart12,
            widgetChart13,
            widgetChart14,
            tab: null,
            tabItems: [
                {
                    title: 'Addidas Template',
                    subTitle: 'Landing Page',
                    percent: 80,
                    progressColor: 'primary'
                },
                {
                    title: 'Agency Template',
                    subTitle: 'Single Page',
                    percent: 50,
                    progressColor: 'danger'
                },
                {
                    title: 'Vuej.s Template',
                    subTitle: 'SPA',
                    percent: 20,
                    progressColor: 'orange'
                },
                {
                    title: 'Medical Template',
                    subTitle: 'SPA',
                    percent: 90,
                    progressColor: 'green'
                },
                {
                    title: 'Reaact.js Template',
                    subTitle: 'Admin Dashboard',
                    percent: 70,
                    progressColor: 'purple'
                }
            ]
        }
    }
}
