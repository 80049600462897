//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TestCard from '@/components/card/TestCard'
import PriceCard from '@/components/card/PriceCard'
import {
    gradientLineChart,
    salesByCountries,
    visitorsAnalytic
} from '@/data/sales2'
export default {
    name: 'Sales',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Sales'
    },
    components: {
        'test-card': TestCard,
        'price-card': PriceCard
    },
    data() {
        return {
            gradientLineChart,
            salesByCountries,
            visitorsAnalytic,
            series: [
                {
                    name: 'series-1',
                    data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
            ],
            desserts: [
                {
                    orderId: '#0032',
                    img: require('@/assets/images/faces/1.jpg'),
                    customer: 'Jhon Doe',
                    date: '22 Mar 20, 6:30 PM',
                    price: '950',
                    status: 'Paid',
                    actions: 'asd'
                },
                {
                    orderId: '#0032',
                    img: require('@/assets/images/faces/2.jpg'),
                    customer: 'Jhon Doe',
                    date: '22 Mar 20, 6:30 PM',
                    price: '950',
                    status: 'Paid',
                    actions: 'asd'
                },
                {
                    orderId: '#0032',
                    img: require('@/assets/images/faces/3.jpg'),
                    customer: 'Jhon Doe',
                    date: '22 Mar 20, 6:30 PM',
                    price: '950',
                    status: 'Paid',
                    actions: 'asd'
                },
                {
                    orderId: '#0032',
                    img: require('@/assets/images/faces/4.jpg'),
                    customer: 'Jhon Doe',
                    date: '22 Mar 20, 6:30 PM',
                    price: '950',
                    status: 'Paid',
                    actions: 'asd'
                }
            ],
            items: [
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Dan Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Jamie Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Jhon Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                }
            ]
        }
    }
}
