//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Chips'
    },
    data() {
        return {
            // custom lists
            menu: false,
            chips: [
                'Programming',
                'Playing video games',
                'Watching movies',
                'Sleeping'
            ],
            inSelectItems: ['Streaming', 'Eating'],
            items: [
                {
                    text: 'Nature',
                    icon: 'mdi-nature'
                },
                {
                    text: 'Nightlife',
                    icon: 'mdi-glass-wine'
                },
                {
                    text: 'November',
                    icon: 'mdi-calendar-range'
                },
                {
                    text: 'Portland',
                    icon: 'mdi-map-marker'
                },
                {
                    text: 'Biking',
                    icon: 'mdi-bike'
                }
            ],
            loading: false,
            search: '',
            selected: []
        }
    },
    // custom lists
    computed: {
        allSelected() {
            return this.selected.length === this.items.length
        },
        categories() {
            const search = this.search.toLowerCase()

            if (!search) return this.items

            return this.items.filter((item) => {
                const text = item.text.toLowerCase()

                return text.indexOf(search) > -1
            })
        },
        selections() {
            const selections = []

            for (const selection of this.selected) {
                selections.push(selection)
            }

            return selections
        }
    },

    watch: {
        selected() {
            this.search = ''
        }
    },
    methods: {
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1)
            this.chips = [...this.chips]
        },
        // icon
        close() {
            alert('Chip close clicked')
        },

        // action chips
        alarm() {
            alert('Turning on alarm...')
        },
        blinds() {
            alert('Toggling Blinds...')
        },
        lights() {
            alert('Toggling lights...')
        },
        // custom lists
        next() {
            this.loading = true

            setTimeout(() => {
                this.search = ''
                this.selected = []
                this.loading = false
            }, 2000)
        }
    }
}
