//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Table Three'
    },
    data() {
        return {
            search: '',
            selected: [],
            headers: [
                {
                    text: 'Customer',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {text: 'Store Name', value: 'calories'},
                {text: 'Products', value: 'fat'},
                {text: 'Wallet Balance', value: 'carbs'},
                {text: 'Create Date', value: 'protein'},
                {text: 'Revenue', value: 'iron'},
                {text: 'Action', value: 'action'}
            ],
            desserts: [
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    value: [423, 446, 675, 510, 590, 610, 760]
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    value: [300, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, -675, -450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [600, 100, 675, 450, 290, 610, 400]
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe two',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    value: [423, 446, 675, 510, 590, 610, 760]
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe two',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    value: [300, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend two',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut two',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, -675, -450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa two',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [600, 100, 675, 450, 290, 610, 400]
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe three',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    value: [300, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend three',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut three',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, -675, -450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa three',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [600, 100, 675, 450, 290, 610, 400]
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe four',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    value: [423, 446, 675, 510, 590, 610, 760]
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe four',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    value: [300, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend four',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, 675, 450, 590, 310, 200]
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut four',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    value: [100, 100, -675, -450, 590, 310, 200]
                }
            ]
        }
    }
}
