//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Validation'
    },
    data() {
        return {
            valid: false,
            firstname: '',
            lastname: '',
            nameRules: [
                (v) => !!v || 'Name is required',
                (v) => v.length <= 10 || 'Name must be less than 10 characters'
            ],
            email: '',
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            // Validation with submit & clear
            valid2: true,
            name: '',
            nameRules2: [
                (v) => !!v || 'Name is required',
                (v) =>
                    (v && v.length <= 10) ||
                    'Name must be less than 10 characters'
            ],
            email2: '',
            emailRules2: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            select: null,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
            checkbox: false,
            // creating rules
            allowSpaces: false,
            match: 'Foobar',
            max: 0,
            model: 'Foobar'
        }
    },
    // creating rules
    computed: {
        rules() {
            const rules = []

            if (this.max) {
                const rule = (v) =>
                    (v || '').length <= this.max ||
                    `A maximum of ${this.max} characters is allowed`

                rules.push(rule)
            }

            if (!this.allowSpaces) {
                const rule = (v) =>
                    (v || '').indexOf(' ') < 0 || 'No spaces are allowed'

                rules.push(rule)
            }

            if (this.match) {
                const rule = (v) =>
                    (!!v && v) === this.match || 'Values do not match'

                rules.push(rule)
            }

            return rules
        }
    },

    watch: {
        match: 'validateField',
        max: 'validateField',
        model: 'validateField'
    },
    // Validation with submit & clear
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        // creating rules
        validateField() {
            this.$refs.form.validate()
        }
    }
}
