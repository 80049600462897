//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartCard from '@/components/card/ChartCard'
import {
    apexDemo,
    basicArea,
    splineArea,
    dateTimeXaxis,
    negativeArea,
    stackedArea,
    nullAreaChart
} from '@/data/apexChart'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Apex- Area Charts'
    },

    components: {
        ChartCard
    },
    data() {
        return {
            apexDemo,
            basicArea,
            splineArea,
            dateTimeXaxis,
            negativeArea,
            stackedArea,
            nullAreaChart
        }
    },
    methods: {}
}
