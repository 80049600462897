//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        cardIcon: {
            type: String,
            default: 'mdi-chart-pie'
        },
        colorIcon: {
            type: String,
            default: 'text-primary'
        },
        chipColor: {
            type: String,
            default: 'warning'
        },
        title: {
            type: String,
            default: 'test'
        },
        // chipText: {
        //   type: String,
        //   default: "A"
        // },
        amount: {
            type: String,
            default: '95065'
        },
        footerText: {
            type: String,
            default: 'hello world'
        },
        footerClass: {
            type: String,
            default: 'grey--text'
        }
    }
}
