//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    AppFooter: () => import("./Footer.vue"),
  },
};
