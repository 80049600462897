//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import avatarGroupCard from '@/components/card/AvatarGroupCard'
export default {
    name: 'overview',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Overview'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            search: '',
            selected: [],
            singleSelect: false,
            headers: [
                {
                    text: 'PROJECT',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {text: 'TASKS', value: 'task'},
                {text: 'MEMBERS', value: 'members'},
                {text: 'STATUS', value: 'status'},
                {text: 'COMPLETION', value: 'completion'},
                {text: 'DUE DATE', value: 'date'}
            ],
            desserts: [
                {
                    img: require('@/assets/images/BrandSVG/dell.svg'),
                    name: 'Dell',
                    timeUpload: 'Updated 9min ago',
                    task: 159,
                    members: 6.0,
                    status: 'In Progress',
                    completion: 50,
                    date: '2 Feb',
                    statusColor: 'primary'
                },
                {
                    img: require('@/assets/images/BrandSVG/apple.svg'),
                    name: 'Apple',
                    timeUpload: 'Updated 2min ago',
                    task: 159,
                    members: 6.0,
                    status: 'In Progress',
                    completion: 70,
                    date: '2 Jan',
                    statusColor: 'primary'
                },
                {
                    img: require('@/assets/images/BrandSVG/cisco.svg'),
                    name: 'Cisco',
                    timeUpload: 'Updated 9min ago',
                    task: 159,
                    members: 6.0,
                    status: 'Completion',
                    completion: 100,
                    date: '2 Jan',
                    statusColor: 'success'
                },

                {
                    img: require('@/assets/images/BrandSVG/asus.svg'),
                    name: 'Asus',
                    timeUpload: 'Updated 9min ago',
                    task: 159,
                    members: 6.0,
                    status: 'In Progress',
                    completion: 40,
                    date: '2 Feb',
                    statusColor: 'primary'
                },
                {
                    img: require('@/assets/images/BrandSVG/intel.svg'),
                    name: 'Intel',
                    timeUpload: 'Updated 9min ago',
                    task: 159,
                    members: 6.0,
                    status: 'Completion',
                    completion: 100,
                    date: '2 Feb',
                    statusColor: 'success'
                },
                {
                    img: require('@/assets/images/BrandSVG/motorola.svg'),
                    name: 'Motorola',
                    timeUpload: 'Updated 2min ago',
                    task: 159,
                    members: 6.0,
                    status: 'Completion',
                    completion: 100,
                    date: '2 Feb',
                    statusColor: 'success'
                },
                {
                    img: require('@/assets/images/BrandSVG/vaio.svg'),
                    name: 'VAIO',
                    timeUpload: 'Updated 2min ago',
                    task: 159,
                    members: 6.0,
                    status: 'In Progress',
                    completion: 44,
                    date: '2 Feb',
                    statusColor: 'primary'
                },
                {
                    img: require('@/assets/images/BrandSVG/lg.svg'),
                    name: 'LG',
                    timeUpload: 'Updated 2min ago',
                    task: 159,
                    members: 6.0,
                    status: 'In Progress',
                    completion: 95,
                    date: '2 Feb',
                    statusColor: 'primary'
                }
            ],
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Overview',
                    disabled: true,
                    href: '/overview'
                }
            ]
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name
        }
    }
}
