//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {},
    data() {
        return {
            images: [
                {
                    avatar: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon'
                },
                {
                    avatar: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Alison'
                },
                {
                    avatar: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Alison W'
                }
            ]
        }
    }
}
