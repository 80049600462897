//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import avatarGroupCard from '@/components/card/AvatarGroupCard'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Table'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            headers: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {text: 'Date', value: 'date'},
                {text: 'Description', value: 'desc'},
                {text: 'Category', value: 'category'},
                {text: 'Amount', value: 'amount'},
                {text: 'Status', value: 'status'}
            ],
            desserts: [
                {
                    name: require('@/assets/images/logos/001-spotify.svg'),
                    date: '2 July 2020',
                    desc: 'Spotify Subscriptoin fee	',
                    category: 'Music',
                    amount: '-$10',
                    status: 'mdi-dots-horizontal-circle',
                    amountColor: 'danger--text',
                    statusColor: 'warning--text'
                },
                {
                    name: require('@/assets/images/logos/003-apple.svg'),
                    date: '3 July 2020',
                    desc: 'App Store payment',
                    category: 'Software',
                    amount: '-$100',
                    status: 'mdi-check-circle',
                    amountColor: 'danger--text',
                    statusColor: 'success--text'
                },
                {
                    name: require('@/assets/images/logos/004-windows.svg'),
                    date: '3 July 2020',
                    desc: 'Software Purchase',
                    category: 'Software',
                    amount: '$100',
                    status: 'mdi-check-circle',
                    amountColor: 'success--text',
                    statusColor: 'success--text'
                },
                {
                    name: require('@/assets/images/logos/005-google.svg'),
                    date: '3 July 2020',
                    desc: 'Adsense Revenue',
                    category: 'Software',
                    amount: '-$100',
                    status: 'mdi-check-circle',
                    amountColor: 'danger--text',
                    statusColor: 'success--text'
                }
            ],
            headersTwo: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {text: 'Name', value: 'folderName'},
                {text: 'Date', value: 'date'},
                {text: 'Members', value: 'member'},
                {text: '', value: 'action'}
            ],
            dessertsTwo: [
                {
                    name: require('@/assets/images/file-types/001-pdf.svg'),
                    folderName: 'Project_alpha_report.pdf',
                    date: '01/10/2020	',
                    member: '',
                    action: 'mdi-dots-horizontal-circle'
                },
                {
                    name: require('@/assets/images/file-types/002-psd.svg'),
                    folderName: 'Project_alpha_UI.pdf',
                    date: '01/10/2020	',
                    member: '',
                    action: 'mdi-dots-horizontal-circle'
                },
                {
                    name: require('@/assets/images/file-types/004-xlsx.svg'),
                    folderName: 'Project_alpha_UI.pdf',
                    date: '01/10/2020	',
                    member: '',
                    action: 'mdi-dots-horizontal-circle'
                }
            ],
            headersThree: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {text: 'Started', value: 'started'},
                {text: 'Course', value: 'course'},
                {text: 'Progress', value: 'progress'},
                {text: 'Play', value: 'action'}
            ],
            dessertsThree: [
                {
                    name: require('@/assets/images/logos/angular.png'),
                    started: '20 May, 20200',
                    course: 'Angular Beyond The Basics',
                    progress: 70,
                    action: ''
                },
                {
                    name: require('@/assets/images/logos/react.png'),
                    started: '20 May, 20200',
                    course: 'React Development Course',
                    progress: 40,
                    action: ''
                },
                {
                    name: require('@/assets/images/logos/webpack.png'),
                    started: '20 May, 20200',
                    course: 'Webpack For Busy Developer',
                    progress: 50,
                    action: ''
                },
                {
                    name: require('@/assets/images/logos/sass.png'),
                    started: '20 May, 20200',
                    course: 'Complete SASS Course',
                    progress: 50,
                    action: ''
                },
                {
                    name: require('@/assets/images/logos/bootstrap.png'),
                    started: '20 May, 20200',
                    course: '	Bootstrap For Everyone',
                    progress: 50,
                    action: ''
                }
            ]
        }
    }
}
