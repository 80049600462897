//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textColor: {
      type: String,
      default: "primary--text",
    },
    bgColor: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "mdi-home",
    },
    iconColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
  },
};
