//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: 'More'
        }
    }
}
