//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lmCard from "@/components/card/LmsCard";
import { welcomeProgressChart, studyChart } from "@/data/learningManagement";
export default {
  name: "LearningManagement",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Learning Management",
  },
  components: {
    lmCard,
  },
  data() {
    return {
      welcomeProgressChart,
      studyChart,
      valueDeterminate: 50,
      valueDeterminate2: 80,
      desserts: [
        {
          logo: require("@/assets/images/Angular_Icon.png"),
          started: "20 May, 2020",
          course: "Angular Beyond The Basics",
          progress: 50,
        },
        {
          logo: require("@/assets/images/react.png"),
          started: "20 May, 2020",
          course: "React Development Course",
          progress: 30,
        },
        {
          logo: require("@/assets/images/webpack.png"),
          started: "20 May, 2020",
          course: "Webpack For Busy Developer",
          progress: 50,
        },
        {
          logo: require("@/assets/images/Sass_icon.png"),
          started: "20 May, 2020",
          course: "Bootstrap For Everyone",
          progress: 70,
        },
        {
          logo: require("@/assets/images/bootstrap-logo.png"),
          started: "20 May, 2020",
          course: "React Development Course",
          progress: 20,
        },
      ],
      items: [
        {
          icon: "mdi-view-column",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Data structure test",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-book-open-variant",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Design Pattern Test",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-gamepad",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Algorithm Test",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-calendar-text",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Spanish 201",
          subtitle: "23 Dec 2020",
        },
      ],
      itemsTwo: [
        {
          icon: "mdi-web",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Create Simple Wrbsite",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-view-list-outline",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Simple Todo App",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-apps-box",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Invoice App",
          subtitle: "23 Dec 2020",
        },
        {
          icon: "mdi-order-bool-ascending-variant",
          iconClass: "dark--text text--lighten-4 grey lighten-5",
          title: "Order management app",
          subtitle: "23 Dec 2020",
        },
      ],
    };
  },
};
