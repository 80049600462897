//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data: () => ({
        metaInfo: {
            // title will be injected into parent titleTemplate
            title: 'Overflow Buttons'
        },
        // usage
        dropdown_font: ['Arial', 'Calibri', 'Courier', 'Verdana'],
        // counter
        dropdown_edit: [
            {text: '100%'},
            {text: '75%'},
            {text: '50%'},
            {text: '25%'},
            {text: '0%'}
        ],
        // segmented
        dropdown_icon: [
            {text: 'list', callback: () => console.log('list')},
            {text: 'favorite', callback: () => console.log('favorite')},
            {text: 'delete', callback: () => console.log('delete')}
        ]
    })
}
