//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";

export default {
  name: "Chat",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Chat",
  },
  created() {
    axios
      .get("/api/chat/contacts")
      .then((response) => (this.contacts = response.data))
      .catch((err) => console.log(err));
    axios
      .get("/api/chat/chats")
      .then((response) => (this.chats = response.data));
  },
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    isOpen: false,
    isBlock: false,
    itemsSb: [
      {
        active: true,
        title: "Jason Oner",
        avatar: require("@/assets/images/faces/13.jpg"),
        subtitle: "<span class=''>3 Oct, 2018</span>",
      },
    ],
    currentChat: [],
    chatList: [],
    chatInfo: [],
    singleChatInfo: [],
    isLoading: false,
    item: 1,
    contacts: [],
    chats: [],
    newMessage: "",
  }),
  methods: {
    contactView(contact) {
      let contactID = contact.id;

      axios.get("/api/chat/id", { contactID }).then((response) => {
        // console.log(response.data.chats)
        this.singleChatInfo = response.data.chats;
      });
    },

    sendMessage() {
      // this.chats.push({
      //   text: this.newMessage,
      //   textTwo: "Hi, I'm Jason Oner. Your imaginary friend."
      // })

      this.singleChatInfo.push({
        textTwo: this.newMessage,
        avatar: require("@/assets/images/faces/2.jpg"),
        text: "Hi, I'm Jason Oner. Your imaginary friend.",
      });
      this.newMessage = "";
    },

    appToggle: function () {
      this.isOpen = !this.isOpen;
      this.isBlock = !this.isBlock;
    },
    overlayApp: function () {
      this.isOpen = !this.isOpen;
      this.isBlock = !this.isBlock;
    },
  },
  //   sendMessage: function() {
  //     axios.post('/api/chatInfo', { data: this.newChat })
  //       .then(response => {
  //         console.log(response.data);
  //         this.chatInfo.push({text: response.data})
  //         // setTimeout(function(){ alert("Hello"); }, 2000);
  //         setTimeout(() => {
  //           this.chatInfo.push({text: 'hello test'})
  //         }, 3000);
  //       })
  //     this.newChat = ''

  //   }

  // },
};
