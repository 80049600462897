//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import avatarGroupCard from '@/components/card/AvatarGroupCard'
import {
    jmPieChartOne,
    jmPieChartTwo,
    jmBarChart,
    jmLineChart,
    jmDonutChart
} from '@/data/jobManagement'

export default {
    name: 'job-management',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Job Management'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            jmPieChartOne,
            jmPieChartTwo,
            jmBarChart,
            jmLineChart,
            jmDonutChart,
            valueDeterminate: 50,
            valueDeterminate2: 20,
            valueDeterminate3: 60,
            valueDeterminate4: 34,
            valueDeterminate5: 35,
            valueDeterminate6: 15,
            desserts: [
                {
                    job: 'Senior Fullstack Engineer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Freelance Java Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Python Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Product Designer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                }
            ],
            items: [
                {
                    icon: 'mdi-view-column',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Subscription expires today',
                    subtitle: '23 Dec 2020'
                },
                {
                    icon: 'mdi-close-circle',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'You unpublished a Job',
                    subtitle: '23 Dec 2020'
                },
                {
                    icon: 'mdi-account-group',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: '5 New applicants',
                    subtitle: '23 Dec 2020'
                },
                {
                    icon: 'mdi-comment-account-outline',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'New Commnet on job post',
                    subtitle: '23 Dec 2020'
                }
            ]
        }
    }
}
